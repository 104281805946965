import React, { Component } from 'react'
import { connect } from "react-redux";
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
    updateBhodiFaqsGuests,
} from "../../Redux/actions/authActions";
import axios from "axios";
import firebase from "../../Config/Firebase";
import logolandingpage from "../../assets/logolandingpage.png";
import logolandingpagemobile from "../../assets/landingpagelogomobile.png";
import playiconlandingpage from "../../assets/playiconlandingpage.png"
import playiconlandingpagemobile from "../../assets/playiconlogomobile.png"
import playannouncementpic from "../../assets/playannouncementpic.png"
import playannouncementpicmobile from "../../assets/playannouncementpicmobile.png"
import resortspiclandingpage from "../../assets/resortspiclandingpage.png"
import resortspiclandingpagemobile from "../../assets/resortspiclandingpagemobile.png"
import "./index.css"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { BsPersonFill } from 'react-icons/bs';
import countries from "../../DataJSON/countries.json";
import { MdClose } from 'react-icons/md';
import Footer from '../../Components/Footer';
import ReactFullpage from "@fullpage/react-fullpage";
import FooterGreen from '../../Components/FooterGreen';
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import CloseIcon from "@material-ui/icons/Close"
import swal from 'sweetalert';
import { Switch } from '@material-ui/core';
import { checkForSubscribedExclusives } from '../../Functions/checkForSubscribedExclusives';
import SignupComponent from "../../Components/SignupComponent"
import NewStoryPopup from '../MemberHome/NewStoryPopup';
import { history } from '../../Config/Routes';
import MenuIcon from "@material-ui/icons/Menu";
import landingPageMainPic from "../../assets/landingpagenewpic.jpeg"
import CharityLandingPage from '../CharityLandingsPage';
import NewProductWrapper from '../ProductsPage/NewProductsWrapper';
import { mergeAdminEvent } from '../../Functions/globalFunctions';
import EventsNewSection from '../../Components/EventsNewSection';
import FooterBasket from '../../Components/FooterBasket';
import { OnboardingTemplate } from '../../Components/EmailTemplates/OnboardingTemplate';
import GuestFaqComponent from './GuestFaqComponent';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { ChevronLeft, ExpandMore } from "@material-ui/icons";
import FormComponent from './FormComponent';

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class LandingPage extends Component {
    constructor() {
        super()
        this.state = {
            showDutch: true,
            landing_page_banner_top_video: "",
            showLoginPanel: false,
            showVideoPanel: false,
            secondStep: false,
            aanmeldenloader: false,
            subscription_data: [],
            country: "155",
            password: "",
            passwordPopup: false,
            houseNumber: "",
            houseNumberPopup: false,
            firstName: "",
            firstNamePopup: false,
            street: "",
            streetPopup: false,
            lastName: "",
            lastNamePopup: false,
            zipCode: "",
            zipCodePopup: false,
            city: "",
            cityPopup: false,
            email: "",
            emailPopup: false,
            phoneNumber: "",
            phoneNumberPopup: false,
            allUsers: [],
            agreed: false,
            agreedPopup: false,
            currentUserSpecific: "",
            showForm: false,
            checkCity: "",
            showText: true,
            loginEmail: "",
            loginEmailPopup: false,
            loginPassword: "",
            loginPasswordPopup: false,

            companyGuestStoryTitle: "",
            companyGuestStoryDate: "",
            companyGuestStoryDesc: "",
            companyGuestStoryInfo: "",
            companyGuestStoryButtonText: "",
            companyGuestStoryVideo: "",
            companyGuestStoryVideoPopup: "",
            specialTextOneStoryGuest: "FASHION",
            specialTextTwoStoryGuest: "WATCHES",
            specialTextThreeStoryGuest: "LIFESTYLE",
            specialTextFourthStoryGuest: "FOOD",

            aanmeldenloaderSubscribing: false,
            subscriptionAmountProducts: "",

            organizationId: "",
            plan_amount_exclusive: "",
            isUserForm: true,

            companyName: "",
            kvkCompany: "",
            company_website: "",

            stripe_plan_key: "",

            alreadyInvitedCompanies: [],
            alreadyInvitedCompaniesManual: [],

            special_products_text_dutch: "",
            special_products_text_dutch_mobile: "",
            special_products_text_english: "",
            special_products_text_english_mobile: "",
            speical_products_page_top_logo: "",
            speical_products_page_play_icon: "",
            speical_products_page_play_picture: "",
            speical_products_page_top_logo_mobile: "",
            speical_products_page_play_icon_mobile: "",
            speical_products_page_play_picture_mobile: "",
            speical_products_page_banner_image: "",

            showFormNewOne: false,


            brands_page_text_dutch: "",
            brands_page_text_dutch_mobile: "",
            brands_page_text_english: "",
            brands_page_text_english_mobile: "",
            brands_page_top_logo: "",
            brands_page_play_icon: "",
            brands_page_play_picture: "",
            brands_page_top_logo_mobile: "",
            brands_page_play_icon_mobile: "",
            brands_page_play_picture_mobile: "",
            brands_page_banner_image: "",

            company_on_landing_page: "",

            currentCampaignToShow: "",


            landing_page_new_dutch_description: "",
            landing_page_new_dutch_description_mobile: "",
            landing_page_new_english_description: "",
            landing_page_new_english_description_mobile: "",
            landing_page_new_dutch_point_one: "",
            landing_page_new_dutch_point_one_mobile: "",
            landing_page_new_english_point_one: "",
            landing_page_new_english_point_one_mobile: "",
            landing_page_new_dutch_point_two: "",
            landing_page_new_dutch_point_two_mobile: "",
            landing_page_new_english_point_two: "",
            landing_page_new_english_point_two_mobile: "",
            landing_page_new_dutch_point_three: "",
            landing_page_new_dutch_point_three_mobile: "",
            landing_page_new_english_point_three: "",
            landing_page_new_english_point_three_mobile: "",
            landing_page_new_logo_main: "",
            landing_page_new_logo_main_mobile: "",
            landing_page_new_logo_small: "",
            landing_page_new_logo_small_mobile: "",
            landing_page_new_logo_play: "",
            landing_page_new_logo_play_mobile: "",
            landing_page_new_bg_media: "",
            landing_page_new_bg_media_mobile: "",
            landing_page_media_type: "",
            landing_page_media_type_mobile: "",

            bhodiFaqsGuests: [],
            arrDropdownsGuests: [],
        }
    }

    componentDidMount() {
        // if (window.innerWidth < 1008) {
        document.getElementById("root").style.overflow = "hidden";
        // }

        if (!this.props.global_values && this.props.onSpecialProducts) {
            this.setState({ showText: false })
        }

        firebase.database().ref("accounts_created_uniquelink").on("value", (data) => {
            let a = data.val()
            if (a) {
                let objected = Object.entries(a)
                let arr = []
                if (objected.length > 0) {
                    objected.map((t) => {
                        arr.push(t[1])
                    })
                    this.setState({ alreadyInvitedCompanies: arr })
                }
            }
        })
        firebase.database().ref("accounts_created_uniquelink_manual").on("value", (data) => {
            let a = data.val()
            if (a) {
                let objected = Object.entries(a)
                let arr = []
                if (objected.length > 0) {
                    objected.map((t) => {
                        arr.push(t[1])
                    })
                    this.setState({ alreadyInvitedCompaniesManual: arr })
                }
            }
        })

        if (this.props.global_campaigns && !this.props.onResortsPage) {
            let adminCampaign = this.props.global_campaigns.filter((g) => {
                return g[1].created_by === process.env.REACT_APP_ADMIN_ID && g[1].campaignType === "local_event"
            })
            if (adminCampaign.length > 0) {
                let campaignsJoined = this.props.global_campaigns.filter((t) => {
                    return t[1].takeFromAdmin
                })
                let arrTimingsAvailable = [];
                if (campaignsJoined.length > 0) {
                    campaignsJoined.map((campInner) => {
                        let g = campInner[1]
                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                        if (g.timings) {
                            g.timings.map((e) => {
                                if (e.isoStartTime && e.isoEndTime) {
                                    let checkedInOfCurrentBlock = [];
                                    if (g.checked_in_by) {
                                        Object.entries(g.checked_in_by).map((t) => {
                                            if (t[1].event_id === e.eventId) {
                                                checkedInOfCurrentBlock.push(t[1]);
                                            }
                                        });
                                    }
                                    let getCurrentDate = new Date();
                                    if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                        if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                            arrTimingsAvailable.push(e);
                                        }
                                    }
                                }
                            });
                        }
                    })
                }
                if (arrTimingsAvailable.length > 0) {
                    this.setState({ currentCampaignToShow: adminCampaign[0][1] })
                }
            }
        }

        // firebase.database().ref("product_images_to_upload").limitToFirst(3).once("value", (data) => {
        //     let main = data.val()
        //     if (main) {
        //         let objected = Object.entries(main)
        //         objected.map((allImages) => {
        //             let currentToUpload = allImages[1]
        //             let getProductData = new Promise((res, rej) => {
        //                 firebase.database().ref(`bhodi_products/${currentToUpload.productKey}`).once("value", (dataProduct) => {
        //                     let productData = dataProduct.val()
        //                     if (productData) {
        //                         res(productData)
        //                     } else {
        //                         res("err")
        //                     }
        //                 })
        //             })
        //             let uploadImage = new Promise((res, rej) => {
        //                 let imageUrl = currentToUpload.imageUrl
        //                 let splitted = imageUrl.split("/")
        //                 let fileName = splitted[splitted.length - 1]

        //                 var data = JSON.stringify({
        //                     url: imageUrl,
        //                     fileName: fileName,
        //                 })

        //                 var config = {
        //                     method: "post",
        //                     url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/fetchImages",
        //                     headers: {
        //                         'Content-Type': 'application/json'
        //                     },
        //                     data: data,
        //                 }

        //                 axios(config)
        //                     .then((response) => {
        //                         if (response.data.statusCode == 201) {
        //                             res(response.data.imageURL.url)
        //                         } else {
        //                             res("err")
        //                         }
        //                     })
        //                     .catch(() => {
        //                         res("err")
        //                     });
        //             })
        //             Promise.all([getProductData, uploadImage]).then((dataToSave) => {
        //                 let images = []
        //                 if (dataToSave[0] !== "err" && dataToSave[1] !== "err") {
        //                     if (dataToSave[0].images) {
        //                         images = dataToSave[0].images
        //                     }
        //                     images.push(dataToSave[1])

        //                     firebase.database().ref(`bhodi_products/${dataToSave[0].pushKey}/images`).set(images)
        //                     firebase.database().ref(`product_images_to_upload/${currentToUpload.pushKey}`).remove()
        //                 }
        //             })
        //         })
        //     }
        // })


        // firebase
        //     .database()
        //     .ref("users")
        //     .once("value", (data) => {
        //         let a = data.val();
        //         let arr = [];
        //         if (a && !this.state.stopTakingData) {
        //             let objected = Object.entries(a);
        //             objected.map((u) => {
        //                 arr.push(u[1]);
        //             });
        //             let usersWithNumbers = [];
        //             arr.map((t) => {
        //                 if (t.user_id_numbers) {
        //                     usersWithNumbers.push(t);
        //                 }
        //             });
        //             let sorted = usersWithNumbers.sort((a, b) => {
        //                 if (a.user_id_numbers && b.user_id_numbers) {
        //                     return a.user_id_numbers - b.user_id_numbers;
        //                 }
        //             });
        //             this.setState({
        //                 allUsers: arr,
        //                 currentUserSpecific:
        //                     Number(sorted[sorted.length - 1].user_id_numbers) + 1,
        //             });
        //         } else {
        //             this.setState({ allUsers: [] });
        //         }
        //     });

        let res = axios.get(
            "https://us-central1-bhodi-production-da765.cloudfunctions.net/getIpLocation"
        );
        res.then((output) => {
            if (output.data.deta) {
                if (output.data.deta.country === "NL") {
                    this.setState({ showDutch: true });
                } else {
                    this.setState({ showDutch: false });
                }
            }
        });

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    landing_page_banner_top_video: a.landing_page_banner_top_video,

                    landing_page_new_desc: a.landing_page_new_desc,
                    landing_page_new_desc_mobile: a.landing_page_new_desc_mobile,
                    landing_page_new_desc_english: a.landing_page_new_desc_english,
                    landing_page_new_desc_english_mobile: a.landing_page_new_desc_english_mobile,
                    landing_page_new_date: a.landing_page_new_date,
                    landing_page_new_date_mobile: a.landing_page_new_date_mobile,
                    landing_page_new_date_english: a.landing_page_new_date_english,
                    landing_page_new_date_english_mobile: a.landing_page_new_date_english_mobile,

                    landing_page_new_video: a.landing_page_new_video,
                    landing_page_new_video_second: a.landing_page_new_video_second,
                    landing_page_new_video_player: a.landing_page_new_video_player,

                    special_products_text_dutch: a.special_products_text_dutch || "",
                    special_products_text_dutch_mobile: a.special_products_text_dutch_mobile || "",
                    special_products_text_english: a.special_products_text_english || "",
                    special_products_text_english_mobile: a.special_products_text_english_mobile || "",
                    speical_products_page_top_logo: a.speical_products_page_top_logo || "",
                    speical_products_page_play_icon: a.speical_products_page_play_icon || "",
                    speical_products_page_play_picture: a.speical_products_page_play_picture || "",
                    speical_products_page_top_logo_mobile: a.speical_products_page_top_logo_mobile || "",
                    speical_products_page_play_icon_mobile: a.speical_products_page_play_icon_mobile || "",
                    speical_products_page_play_picture_mobile: a.speical_products_page_play_picture_mobile || "",
                    speical_products_page_banner_image: a.speical_products_page_banner_image || "",


                    landing_page_new_dutch_description: a.landing_page_new_dutch_description || "",
                    landing_page_new_dutch_description_mobile: a.landing_page_new_dutch_description_mobile || "",
                    landing_page_new_english_description: a.landing_page_new_english_description || "",
                    landing_page_new_english_description_mobile: a.landing_page_new_english_description_mobile || "",
                    landing_page_new_dutch_point_one: a.landing_page_new_dutch_point_one || "",
                    landing_page_new_dutch_point_one_mobile: a.landing_page_new_dutch_point_one_mobile || "",
                    landing_page_new_english_point_one: a.landing_page_new_english_point_one || "",
                    landing_page_new_english_point_one_mobile: a.landing_page_new_english_point_one_mobile || "",
                    landing_page_new_dutch_point_two: a.landing_page_new_dutch_point_two || "",
                    landing_page_new_dutch_point_two_mobile: a.landing_page_new_dutch_point_two_mobile || "",
                    landing_page_new_english_point_two: a.landing_page_new_english_point_two || "",
                    landing_page_new_english_point_two_mobile: a.landing_page_new_english_point_two_mobile || "",
                    landing_page_new_dutch_point_three: a.landing_page_new_dutch_point_three || "",
                    landing_page_new_dutch_point_three_mobile: a.landing_page_new_dutch_point_three_mobile || "",
                    landing_page_new_english_point_three: a.landing_page_new_english_point_three || "",
                    landing_page_new_english_point_three_mobile: a.landing_page_new_english_point_three_mobile || "",
                    landing_page_new_logo_small: a.landing_page_new_logo_small || "",
                    landing_page_new_logo_small_mobile: a.landing_page_new_logo_small_mobile || "",
                    landing_page_new_logo_main: a.landing_page_new_logo_main || "",
                    landing_page_new_logo_main_mobile: a.landing_page_new_logo_main_mobile || "",
                    landing_page_new_logo_play: a.landing_page_new_logo_play || "",
                    landing_page_new_logo_play_mobile: a.landing_page_new_logo_play_mobile || "",
                    landing_page_new_bg_media: a.landing_page_new_bg_media || "",
                    landing_page_new_bg_media_mobile: a.landing_page_new_bg_media_mobile || "",
                    landing_page_media_type: a.landing_page_media_type || "",
                    landing_page_media_type_mobile: a.landing_page_media_type_mobile || "",
                });
            }
        }

        if (this.props.onBrandsPage) {
            let a = this.props.currentPageData
            this.setState({
                brands_page_text_dutch: a.main_text_dutch || "",
                brands_page_text_dutch_mobile: a.main_text_dutch_mobile || "",
                brands_page_text_english: a.main_text_english || "",
                brands_page_text_english_mobile: a.main_text_english_mobile || "",
                brands_page_top_logo: a.top_logo || "",
                brands_page_play_icon: a.play_icon || "",
                brands_page_play_picture: a.play_picture || "",
                brands_page_top_logo_mobile: a.top_logo_mobile || "",
                brands_page_play_icon_mobile: a.play_icon_mobile || "",
                brands_page_play_picture_mobile: a.play_picture_mobile || "",
                brands_page_banner_image: a.banner_image || "",
            })
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);
                    this.setState({
                        landing_page_banner_top_video: a.landing_page_banner_top_video,

                        landing_page_new_desc: a.landing_page_new_desc,
                        landing_page_new_desc_mobile: a.landing_page_new_desc_mobile,
                        landing_page_new_desc_english: a.landing_page_new_desc_english,
                        landing_page_new_desc_english_mobile: a.landing_page_new_desc_english_mobile,
                        landing_page_new_date: a.landing_page_new_date,
                        landing_page_new_date_mobile: a.landing_page_new_date_mobile,
                        landing_page_new_date_english: a.landing_page_new_date_english,
                        landing_page_new_date_english_mobile: a.landing_page_new_date_english_mobile,

                        landing_page_new_video: a.landing_page_new_video,
                        landing_page_new_video_second: a.landing_page_new_video_second,
                        landing_page_new_video_player: a.landing_page_new_video_player,

                        special_products_text_dutch: a.special_products_text_dutch || "",
                        special_products_text_dutch_mobile: a.special_products_text_dutch_mobile || "",
                        special_products_text_english: a.special_products_text_english || "",
                        special_products_text_english_mobile: a.special_products_text_english_mobile || "",
                        speical_products_page_top_logo: a.speical_products_page_top_logo || "",
                        speical_products_page_play_icon: a.speical_products_page_play_icon || "",
                        speical_products_page_play_picture: a.speical_products_page_play_picture || "",
                        speical_products_page_top_logo_mobile: a.speical_products_page_top_logo_mobile || "",
                        speical_products_page_play_icon_mobile: a.speical_products_page_play_icon_mobile || "",
                        speical_products_page_play_picture_mobile: a.speical_products_page_play_picture_mobile || "",
                        speical_products_page_banner_image: a.speical_products_page_banner_image || "",

                        company_on_landing_page: a.company_on_landing_page || "",

                        showText: true,


                        landing_page_new_dutch_description: a.landing_page_new_dutch_description || "",
                        landing_page_new_dutch_description_mobile: a.landing_page_new_dutch_description_mobile || "",
                        landing_page_new_english_description: a.landing_page_new_english_description || "",
                        landing_page_new_english_description_mobile: a.landing_page_new_english_description_mobile || "",
                        landing_page_new_dutch_point_one: a.landing_page_new_dutch_point_one || "",
                        landing_page_new_dutch_point_one_mobile: a.landing_page_new_dutch_point_one_mobile || "",
                        landing_page_new_english_point_one: a.landing_page_new_english_point_one || "",
                        landing_page_new_english_point_one_mobile: a.landing_page_new_english_point_one_mobile || "",
                        landing_page_new_dutch_point_two: a.landing_page_new_dutch_point_two || "",
                        landing_page_new_dutch_point_two_mobile: a.landing_page_new_dutch_point_two_mobile || "",
                        landing_page_new_english_point_two: a.landing_page_new_english_point_two || "",
                        landing_page_new_english_point_two_mobile: a.landing_page_new_english_point_two_mobile || "",
                        landing_page_new_dutch_point_three: a.landing_page_new_dutch_point_three || "",
                        landing_page_new_dutch_point_three_mobile: a.landing_page_new_dutch_point_three_mobile || "",
                        landing_page_new_english_point_three: a.landing_page_new_english_point_three || "",
                        landing_page_new_english_point_three_mobile: a.landing_page_new_english_point_three_mobile || "",
                        landing_page_new_logo_main: a.landing_page_new_logo_main || "",
                        landing_page_new_logo_main_mobile: a.landing_page_new_logo_main_mobile || "",
                        landing_page_new_logo_small: a.landing_page_new_logo_small || "",
                        landing_page_new_logo_small_mobile: a.landing_page_new_logo_small_mobile || "",
                        landing_page_new_logo_play: a.landing_page_new_logo_play || "",
                        landing_page_new_logo_play_mobile: a.landing_page_new_logo_play_mobile || "",

                        landing_page_new_bg_media: a.landing_page_new_bg_media || "",
                        landing_page_new_bg_media_mobile: a.landing_page_new_bg_media_mobile || "",
                        landing_page_media_type: a.landing_page_media_type || "",
                        landing_page_media_type_mobile: a.landing_page_media_type_mobile || "",
                    });
                }
            });

        if (this.props.currentUserMember && !this.props.atProductsPage && !this.props.onProductsGuest) {
            let a = this.props.currentUserMember
            if (a.company_guest_story_button_text) {
                this.setState({
                    companyGuestStoryButtonText: a.company_guest_story_button_text,
                })
            }
            if (a.company_guest_story_video) {
                this.setState({
                    companyGuestStoryVideo: a.company_guest_story_video,
                })
            }
            if (a.company_guest_story_title) {
                this.setState({
                    companyGuestStoryTitle: a.company_guest_story_title,
                })
            }
            if (a.company_guest_story_desc) {
                this.setState({
                    companyGuestStoryDesc: a.company_guest_story_desc,
                })
            }
            if (a.company_guest_story_info) {
                this.setState({
                    companyGuestStoryInfo: a.company_guest_story_info,
                })
            }
            if (a.company_guest_story_date) {
                this.setState({
                    companyGuestStoryDate: a.company_guest_story_date,
                })
            }
            if (a.company_guest_story_video_popup) {
                this.setState({
                    companyGuestStoryVideoPopup: a.company_guest_story_video_popup,
                })
            }
            if (a.specialTextOneStoryGuest) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextOneStoryGuest,
                })
            }
            if (a.specialTextTwoStoryGuest) {
                this.setState({
                    specialTextTwoStoryGuest: a.specialTextTwoStoryGuest,
                })
            }
            if (a.specialTextThreeStoryGuest) {
                this.setState({
                    specialTextThreeStoryGuest: a.specialTextThreeStoryGuest,
                })
            }
            if (a.specialTextFourthStoryGuest) {
                this.setState({
                    specialTextFourthStoryGuest: a.specialTextFourthStoryGuest,
                })
            }
        }

        if (this.props.currentUserMember && this.props.onProductsGuest) {
            // if (this.props.currentUserMember && this.props.currentUserMember.company_product_guest_data && this.props.onProductsGuest) {
            // let a = this.props.currentUserMember
            // if (a.company_product_guest_data.company_product_guest_button_text) {
            //     this.setState({
            //         companyGuestStoryButtonText: a.company_product_guest_data.company_product_guest_button_text,
            //     })
            // }
            // if (a.company_product_guest_data.company_product_guest_video) {
            //     this.setState({
            //         companyGuestStoryVideo: a.company_product_guest_data.company_product_guest_video,
            //     })
            // }
            // if (a.company_product_guest_data.company_product_guest_title) {
            //     this.setState({
            //         companyGuestStoryTitle: a.company_product_guest_data.company_product_guest_title,
            //     })
            // }
            // if (a.company_product_guest_data.company_product_guest_desc) {
            //     this.setState({
            //         companyGuestStoryDesc: a.company_product_guest_data.company_product_guest_desc,
            //     })
            // }
            // if (a.company_product_guest_data.company_product_guest_info) {
            //     this.setState({
            //         companyGuestStoryInfo: a.company_product_guest_data.company_product_guest_info,
            //     })
            // }
            // if (a.company_product_guest_data.company_product_guest_date) {
            //     this.setState({
            //         companyGuestStoryDate: a.company_product_guest_data.company_product_guest_date,
            //     })
            // }
            // if (a.company_product_guest_data.company_product_guest_video_popup) {
            //     this.setState({
            //         companyGuestStoryVideoPopup: a.company_product_guest_data.company_product_guest_video_popup,
            //     })
            // }
            // if (a.company_product_guest_data.specialTextOneStoryGuest) {
            //     this.setState({
            //         specialTextOneStoryGuest: a.company_product_guest_data.specialTextOneStoryGuest,
            //     })
            // }
            // if (a.company_product_guest_data.specialTextTwoStoryGuest) {
            //     this.setState({
            //         specialTextTwoStoryGuest: a.company_product_guest_data.specialTextTwoStoryGuest,
            //     })
            // }
            // if (a.company_product_guest_data.specialTextThreeStoryGuest) {
            //     this.setState({
            //         specialTextThreeStoryGuest: a.company_product_guest_data.specialTextThreeStoryGuest,
            //     })
            // }
            // if (a.company_product_guest_data.specialTextFourthStoryGuest) {
            //     this.setState({
            //         specialTextFourthStoryGuest: a.company_product_guest_data.specialTextFourthStoryGuest,
            //     })
            // }
            let a = this.props.currentUserMember
            if (a.company_products_button_text) {
                this.setState({
                    companyGuestStoryButtonText: a.company_products_button_text,
                })
            }
            if (a.company_products_video) {
                this.setState({
                    companyGuestStoryVideo: a.company_products_video,
                })
            }
            if (a.company_products_title) {
                this.setState({
                    companyGuestStoryTitle: a.company_products_title,
                })
            }
            if (a.company_products_desc) {
                this.setState({
                    companyGuestStoryDesc: a.company_products_desc,
                })
            }
            if (a.company_products_info) {
                this.setState({
                    companyGuestStoryInfo: a.company_products_info,
                })
            }
            if (a.company_products_date) {
                this.setState({
                    companyGuestStoryDate: a.company_products_date,
                })
            }
            if (a.company_products_video_popup) {
                this.setState({
                    companyGuestStoryVideoPopup: a.company_products_video_popup,
                })
            }
            if (a.specialTextOneProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextOneProducts,
                })
            }
            if (a.specialTextTwoProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextTwoProducts,
                })
            }
            if (a.specialTextThreeProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextThreeProducts,
                })
            }
            if (a.specialTextFourthProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextFourthProducts,
                })
            }
            if (a.productsInfo) {
                if (a.productsInfo.subscriptionAmountExclusive) {
                    this.setState({ subscriptionAmountProducts: a.productsInfo.subscriptionAmountExclusive })
                }
            }
            if (a.mollie_credentials) {
                this.setState({ organizationId: a.mollie_credentials.org_id })
            }
        }

        if (this.props.currentUserMember && this.props.atProductsPage && !this.props.onProductsGuest) {
            let a = this.props.currentUserMember
            if (a.company_products_button_text) {
                this.setState({
                    companyGuestStoryButtonText: a.company_products_button_text,
                })
            }
            if (a.company_products_video) {
                this.setState({
                    companyGuestStoryVideo: a.company_products_video,
                })
            }
            if (a.company_products_title) {
                this.setState({
                    companyGuestStoryTitle: a.company_products_title,
                })
            }
            if (a.company_products_desc) {
                this.setState({
                    companyGuestStoryDesc: a.company_products_desc,
                })
            }
            if (a.company_products_info) {
                this.setState({
                    companyGuestStoryInfo: a.company_products_info,
                })
            }
            if (a.company_products_date) {
                this.setState({
                    companyGuestStoryDate: a.company_products_date,
                })
            }
            if (a.company_products_video_popup) {
                this.setState({
                    companyGuestStoryVideoPopup: a.company_products_video_popup,
                })
            }
            if (a.specialTextOneProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextOneProducts,
                })
            }
            if (a.specialTextTwoProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextTwoProducts,
                })
            }
            if (a.specialTextThreeProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextThreeProducts,
                })
            }
            if (a.specialTextFourthProducts) {
                this.setState({
                    specialTextOneStoryGuest: a.specialTextFourthProducts,
                })
            }
            if (a.productsInfo) {
                if (a.productsInfo.subscriptionAmountExclusive) {
                    this.setState({ subscriptionAmountProducts: a.productsInfo.subscriptionAmountExclusive })
                }
            }
            if (a.mollie_credentials) {
                this.setState({ organizationId: a.mollie_credentials.org_id })
            }
        }


        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                let arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }

        let arr = []
        firebase
            .database()
            .ref("subscription_plans")
            .on("child_added", (data) => {
                if (data.val().price !== "0.00") {
                    arr.push(data.val());
                    this.setState({
                        subscription_data: arr,
                    });
                }
            });
    }

    moveToSecondStep() {

        const {
            firstName,
            lastName,
            email,
            agreed,
            password,
            city,
            companyName,
        } = this.state;

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let filteredForEmail = this.state.allUsers.filter((v) => {
            return v.email === email;
        });
        if (firstName === "") {
            this.setState({ firstNamePopup: true });
        } else {
            this.setState({ firstNamePopup: false });
        }
        if (lastName === "") {
            this.setState({ lastNamePopup: true });
        } else {
            this.setState({ lastNamePopup: false });
        }
        if (city === "") {
            this.setState({ cityPopup: true });
        } else {
            this.setState({ cityPopup: false });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailPopup: true });
        } else {
            this.setState({ emailPopup: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passwordPopup: true });
        } else {
            this.setState({ passwordPopup: false });
        }
        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (filteredForEmail.length === 0) {
            this.setState({ emailTookedPopup: false });
        } else {
            if (email && regexEmail.test(email)) {
                this.setState({ emailTookedPopup: true });
            }
        }
        if (!this.state.isUserForm) {
            if (companyName) {
                this.setState({ companyNamePopup: false });
            } else {
                this.setState({ companyNamePopup: true });
                return false;
            }
        }

        if (firstName !== "" && city !== "" && lastName !== "" && email !== "" && regexEmail.test(email) && password !== "" && password.length > 5 && agreed && filteredForEmail.length === 0) {
            this.setState({ secondStep: true })
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.currentPageData !== prevProps.currentPageData) {
            if (this.props.onBrandsPage) {
                let a = this.props.currentPageData
                this.setState({
                    brands_page_text_dutch: a.main_text_dutch || "",
                    brands_page_text_dutch_mobile: a.main_text_dutch_mobile || "",
                    brands_page_text_english: a.main_text_english || "",
                    brands_page_text_english_mobile: a.main_text_english_mobile || "",
                    brands_page_top_logo: a.top_logo || "",
                    brands_page_play_icon: a.play_icon || "",
                    brands_page_play_picture: a.play_picture || "",
                    brands_page_top_logo_mobile: a.top_logo_mobile || "",
                    brands_page_play_icon_mobile: a.play_icon_mobile || "",
                    brands_page_play_picture_mobile: a.play_picture_mobile || "",
                    brands_page_banner_image: a.banner_image || "",
                })
            }
        }


        if (this.props.global_campaigns !== prevProps.global_campaigns && !this.props.onResortsPage) {
            let adminCampaign = this.props.global_campaigns.filter((g) => {
                return g[1].created_by === process.env.REACT_APP_ADMIN_ID && g[1].campaignType === "local_event"
            })
            if (adminCampaign.length > 0) {
                let campaignsJoined = this.props.global_campaigns.filter((t) => {
                    return t[1].takeFromAdmin
                })
                let arrTimingsAvailable = [];
                if (campaignsJoined.length > 0) {
                    campaignsJoined.map((campInner) => {
                        let g = campInner[1]
                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                        if (g.timings) {
                            g.timings.map((e) => {
                                if (e.isoStartTime && e.isoEndTime) {
                                    let checkedInOfCurrentBlock = [];
                                    if (g.checked_in_by) {
                                        Object.entries(g.checked_in_by).map((t) => {
                                            if (t[1].event_id === e.eventId) {
                                                checkedInOfCurrentBlock.push(t[1]);
                                            }
                                        });
                                    }
                                    let getCurrentDate = new Date();
                                    if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                        if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                            arrTimingsAvailable.push(e);
                                        }
                                    }
                                }
                            });
                        }
                    })
                }
                if (arrTimingsAvailable.length > 0) {
                    this.setState({ currentCampaignToShow: adminCampaign[0][1] })
                } else {
                    this.setState({ currentCampaignToShow: "" })
                }
            } else {
                this.setState({ currentCampaignToShow: "" })
            }
        }
    }

    randomStr(e) {
        let len = e;
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489";
        var ans = "";
        for (var i = len; i > 0; i--) {
            ans += arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }


    moveToMollie(specificObject) {
        const {
            firstName,
            lastName,
            email,
            password,
            plan_amount,
            plan_id,
            country,
            city,
            companyName,
            agreed,
            stripe_plan_key,
            isUserForm
        } = specificObject;

        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let filteredForEmail = this.state.allUsers.filter((v) => {
            return v.email === email;
        });
        if (firstName === "") {
            this.setState({ firstNamePopup: true });
        } else {
            this.setState({ firstNamePopup: false });
        }
        if (lastName === "") {
            this.setState({ lastNamePopup: true });
        } else {
            this.setState({ lastNamePopup: false });
        }
        if (city === "") {
            this.setState({ cityPopup: true });
        } else {
            this.setState({ cityPopup: false });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailPopup: true });
        } else {
            this.setState({ emailPopup: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passwordPopup: true });
        } else {
            this.setState({ passwordPopup: false });
        }
        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (filteredForEmail.length === 0) {
            this.setState({ emailTookedPopup: false });
        } else {
            if (email && regexEmail.test(email)) {
                this.setState({ emailTookedPopup: true });
            }
        }
        if (!isUserForm) {
            if (companyName) {
                this.setState({ companyNamePopup: false });
            } else {
                this.setState({ companyNamePopup: true });
                return false;
            }
        }

        if (!(firstName !== "" && city !== "" && lastName !== "" && email !== "" && regexEmail.test(email) && password !== "" && password.length > 5 && agreed && filteredForEmail.length === 0)) {
            return false;
        }

        if (plan_id && plan_amount) {
            let myPlaintextPassword = password;
            bcrypt.genSalt(saltRounds, (err, salt) => {
                bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {
                    let filtered = countries.filter((e) => {
                        return e.id === Number(country);
                    });

                    this.setState({ aanmeldenloader: true });

                    let toCalculateFrom = firstName + lastName

                    if (!isUserForm) {
                        toCalculateFrom = companyName
                    }

                    let jointName = toCalculateFrom;

                    let filteredForJoin = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });

                    if (filteredForJoin.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                        jointName = toCalculateFrom + randomStr;

                        let filteredForJoinSecond = this.state.allUsers.filter((g) => {
                            return g.user_name_id === jointName.toLowerCase();
                        });
                        if (filteredForJoinSecond.length > 0) {
                            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                            jointName = toCalculateFrom + randomStr;

                            let filteredForJoinThird = this.state.allUsers.filter((g) => {
                                return g.user_name_id === jointName.toLowerCase();
                            });
                            if (filteredForJoinThird.length > 0) {
                                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                jointName = toCalculateFrom + randomStr;

                                let filteredForJoinFourth = this.state.allUsers.filter(
                                    (g) => {
                                        return g.user_name_id === jointName.toLowerCase();
                                    }
                                );
                                if (filteredForJoinFourth.length > 0) {
                                    let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                    jointName = toCalculateFrom + randomStr;
                                }
                            }
                        }
                    }

                    if (jointName.includes(" ")) {
                        jointName = jointName.replace(/ /g, "_");
                    }

                    let today = new Date();
                    let date =
                        today.getDate() +
                        "-" +
                        (today.getMonth() + 1) +
                        "-" +
                        today.getFullYear();

                    firebase
                        .auth()
                        .createUserWithEmailAndPassword(email, password)
                        .then((e) => {
                            let userId = e.user.uid;
                            let uid = e.user.uid;
                            localStorage.setItem("userid", userId);
                            let obj = {
                                first_name: firstName,
                                last_name: lastName,
                                email: email,
                                password: hash,
                                // pass: myPlaintextPassword,
                                country: filtered[0].name,
                                passwordChanged: false,
                                user_id: userId,
                                type: "user",
                                user_name_id: jointName.toLowerCase(),
                                created_on: date,
                                newly_registered: false,
                                user_id_numbers: this.state.currentUserSpecific,
                                accountType: "global",
                                street: "",
                                city,
                                zipCode: "",
                                phoneNumber: "",
                                houseNumber: "",
                                profileActive: true,
                                hideWizard: true,
                            };
                            if (this.props.onProductsGuest) {
                                obj.show_exc_loader = true
                            }
                            if (this.state.locationPresent) {
                                obj.latitude = this.state.latitude;
                                obj.longitude = this.state.longitude;
                            }
                            if (!isUserForm) {
                                obj.company_name = companyName;
                                obj.lunch_show_contact = true
                                obj.dinner_show_contact = true
                                obj.dinner_title_contact = "Dinner"
                                obj.lunch_title_contact = "Dinner"
                                obj.type = "company"
                            }
                            firebase
                                .database()
                                .ref("users/" + userId)
                                .set(obj)
                                .then(() => {
                                    // if (this.props.mustFollowUser) {
                                    //     this.followCompany(userId)
                                    // }
                                    localStorage.setItem("userid", e.user.uid);
                                    localStorage.removeItem("cookie_item");
                                    localStorage.setItem("isAdmin", false);
                                    this.props.updateAdminOrNot(false);

                                    let dynamicValues = {
                                        name: firstName + " " + lastName,
                                        email,
                                        password: myPlaintextPassword,
                                    }

                                    let populatedHTML = Object.keys(dynamicValues).reduce(
                                        (template, key) => template.replace(new RegExp(`{{${key}}}`, 'g'), dynamicValues[key]),
                                        OnboardingTemplate
                                    );

                                    var dataForAPI = JSON.stringify({
                                        sendToEmail: email,
                                        subject: "Account Created at Bhodi!",
                                        innerHTMLpart: populatedHTML
                                        // innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><p>First Name: ${firstName}</p><p>Last Name: ${lastName}</p><p>Email: ${email}</p><p>Password: ${myPlaintextPassword}</p></body></html>`,
                                    });

                                    var config = {
                                        method: "post",
                                        url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendEmailMailjet",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        data: dataForAPI,
                                    };

                                    axios(config)
                                        .then((response) => {
                                            let redirect_url = `${window.location.origin}/member/${jointName.toLowerCase()}/charity`
                                            if (this.props.userIdToRedirect) {
                                                if (this.props.onProductsGuest) {
                                                    redirect_url = `${window.location.origin}/company/${this.props.userIdToRedirect}/products`
                                                } else {
                                                    redirect_url = `${window.location.origin}/company/${this.props.userIdToRedirect}`
                                                }
                                            }

                                            axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCustomer",
                                                {
                                                    fullName: firstName + "" + lastName,
                                                    email: email,
                                                }).then((response) => {


                                                    let pushKeyExclusive = ""
                                                    if (this.props.onProductsGuest) {
                                                        let onUserId = this.props.currentUserMember.user_id
                                                        let pushKey = firebase.database().ref(`users/${uid}/subscribedForExclusives`).push().key
                                                        let pushKeySecond = firebase.database().ref(`users/${onUserId}/subscribersForExclusives`).push().key
                                                        let obj = {
                                                            userId: onUserId,
                                                            pushKey,
                                                            payment_status: "pending",
                                                            added_at: Date.now(),
                                                            amount: plan_amount,
                                                        }
                                                        let objSecond = {
                                                            userId: uid,
                                                            pushKey: pushKeySecond,
                                                            payment_status: "pending",
                                                            added_at: Date.now(),
                                                            amount: plan_amount,
                                                            // payment_id: responseSec.data.data.id,
                                                        }
                                                        firebase.database().ref(`users/${uid}/subscribedForExclusives/${pushKey}`).set(obj)
                                                        firebase.database().ref(`users/${onUserId}/subscribersForExclusives/${pushKeySecond}`).set(objSecond)

                                                        pushKeyExclusive = firebase.database().ref(`exclusive_payees/${response.data.data.id}`).push().key
                                                        let objSubscribing = {
                                                            user_id_own: uid,
                                                            user_id_subscribing: onUserId,
                                                            pushKeyOwnSubscribed: pushKey,
                                                            pushKeyOtherSubscribers: pushKeySecond,
                                                            stripe_id_own: response.data.data.id,
                                                            pushKeyOwn: pushKeyExclusive,
                                                        }
                                                        firebase.database().ref(`exclusive_payees/${response.data.data.id}/${pushKeyExclusive}`).set(objSubscribing)

                                                        redirect_url = `${window.location.origin}/company/${this.props.userIdToRedirect}/products?subscribingTo=${pushKeySecond}`
                                                    }

                                                    let paymentObject = {
                                                        // amount: plan_amount,
                                                        customerId: response.data.data.id,
                                                        successUrl: redirect_url,
                                                        cancelUrl: redirect_url,
                                                        // exclusivePaymentWithSubscription: true,
                                                        priceId: stripe_plan_key,
                                                    }
                                                    if (this.props.onProductsGuest) {
                                                        paymentObject.customerExclusivePayeeId = response.data.data.id
                                                        paymentObject.isExclusiveSubscription = true
                                                        paymentObject.onlyExclusiveSubscribe = true
                                                        paymentObject.keyExclusivePayees = pushKeyExclusive
                                                    }

                                                    axios
                                                        .post(
                                                            "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCheckout",
                                                            // {
                                                            //     priceId: stripe_plan_key,
                                                            //     successUrl: redirect_url,
                                                            //     cancelUrl: redirect_url,
                                                            //     customerId: response.data.data.id,
                                                            // }
                                                            paymentObject
                                                        )
                                                        .then((responseSec) => {
                                                            firebase
                                                                .database()
                                                                .ref("users/" + uid + "/stripe_id")
                                                                .set(response.data.data.id);
                                                            if (!this.props.onProductsGuest) {
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/create_subscription")
                                                                    .set(true);
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/plan_id")
                                                                    .set(plan_id);
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/plan_amount")
                                                                    .set(plan_amount);
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/stripe_plan_key")
                                                                    .set(stripe_plan_key);
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/newly_registered")
                                                                    .set(false);
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/payment_id")
                                                                    .set(responseSec.data.data.id)
                                                                    .then(() => {
                                                                        window.location.href = responseSec.data.data.url;
                                                                    });
                                                            } else {
                                                                window.location.href = responseSec.data.data.url;
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                });
                        })
                        .catch((error) => {
                            if (error.code === "auth/email-already-in-use") {
                                this.setState({
                                    loader: false,
                                    stepOne: true,
                                    emailTookedPopup: true,
                                    aanmeldenloader: false,
                                });
                            }
                            console.log(error);
                        });
                });
            });
        }
    }

    createUserAccount(specificObject) {
        const {
            firstName,
            lastName,
            email,
            password,
            country,
            city,
            companyName,
            isUserForm
        } = specificObject;

        let myPlaintextPassword = password;
        bcrypt.genSalt(saltRounds, (err, salt) => {
            bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {
                let filtered = countries.filter((e) => {
                    return e.id === Number(country);
                });

                this.setState({ creatingAccountLoader: true });

                let toCalculateFrom = firstName + lastName

                if (!isUserForm) {
                    toCalculateFrom = companyName
                }

                let jointName = toCalculateFrom;

                let filteredForJoin = this.state.allUsers.filter((g) => {
                    return g.user_name_id === jointName.toLowerCase();
                });

                if (filteredForJoin.length > 0) {
                    let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                    jointName = toCalculateFrom + randomStr;

                    let filteredForJoinSecond = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });
                    if (filteredForJoinSecond.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                        jointName = toCalculateFrom + randomStr;

                        let filteredForJoinThird = this.state.allUsers.filter((g) => {
                            return g.user_name_id === jointName.toLowerCase();
                        });
                        if (filteredForJoinThird.length > 0) {
                            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                            jointName = toCalculateFrom + randomStr;

                            let filteredForJoinFourth = this.state.allUsers.filter(
                                (g) => {
                                    return g.user_name_id === jointName.toLowerCase();
                                }
                            );
                            if (filteredForJoinFourth.length > 0) {
                                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                jointName = toCalculateFrom + randomStr;
                            }
                        }
                    }
                }

                if (jointName.includes(" ")) {
                    jointName = jointName.replace(/ /g, "_");
                }

                let today = new Date();
                let date =
                    today.getDate() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getFullYear();

                firebase
                    .auth()
                    .createUserWithEmailAndPassword(email, password)
                    .then((e) => {
                        let userId = e.user.uid;
                        let uid = e.user.uid;
                        localStorage.setItem("userid", userId);
                        let obj = {
                            first_name: firstName,
                            last_name: lastName,
                            email: email,
                            password: hash,
                            // pass: myPlaintextPassword,
                            country: filtered[0].name,
                            passwordChanged: false,
                            user_id: userId,
                            type: "user",
                            user_name_id: jointName.toLowerCase(),
                            created_on: date,
                            newly_registered: true,
                            user_id_numbers: this.state.currentUserSpecific,
                            accountType: "global",
                            street: "",
                            city,
                            zipCode: "",
                            phoneNumber: "",
                            houseNumber: "",
                            profileActive: true,
                            hideWizard: true,
                        };
                        if (this.state.locationPresent) {
                            obj.latitude = this.state.latitude;
                            obj.longitude = this.state.longitude;
                        }
                        if (!isUserForm) {
                            obj.company_name = companyName;
                            obj.lunch_show_contact = true
                            obj.dinner_show_contact = true
                            obj.dinner_title_contact = "Dinner"
                            obj.lunch_title_contact = "Dinner"
                            obj.type = "company"
                        }
                        firebase
                            .database()
                            .ref("users/" + userId)
                            .set(obj)
                            .then(() => {
                                // if (this.props.mustFollowUser) {
                                //     this.followCompany(userId)
                                // }
                                localStorage.setItem("userid", e.user.uid);
                                localStorage.removeItem("cookie_item");
                                localStorage.setItem("isAdmin", false);
                                this.props.updateAdminOrNot(false);

                                let dynamicValues = {
                                    name: firstName + " " + lastName,
                                    email,
                                    password: myPlaintextPassword,
                                }

                                let populatedHTML = Object.keys(dynamicValues).reduce(
                                    (template, key) => template.replace(new RegExp(`{{${key}}}`, 'g'), dynamicValues[key]),
                                    OnboardingTemplate
                                );

                                var dataForAPI = JSON.stringify({
                                    sendToEmail: email,
                                    subject: "Account Created at Bhodi!",
                                    innerHTMLpart: populatedHTML
                                    // innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><p>First Name: ${firstName}</p><p>Last Name: ${lastName}</p><p>Email: ${email}</p><p>Password: ${myPlaintextPassword}</p></body></html>`,
                                });

                                var config = {
                                    method: "post",
                                    url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendEmailMailjet",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    data: dataForAPI,
                                };

                                axios(config)
                                    .then((response) => {
                                        if (response.data.statusCode === 201) {
                                            // window.location.href = `${window.location.origin
                                            //     }/member/${jointName.toLowerCase()}`;
                                            let redirect_url = ""
                                            if (!isUserForm) {
                                                redirect_url = `${window.location.origin}/company/${jointName.toLowerCase()}`
                                            } else {
                                                redirect_url = `${window.location.origin}/member/${jointName.toLowerCase()}`
                                            }

                                            window.location.href = redirect_url

                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                            });
                    })
                    .catch((error) => {
                        if (error.code === "auth/email-already-in-use") {
                            this.setState({
                                loader: false,
                                stepOne: true,
                                emailTookedPopup: true,
                                creatingAccountLoader: false,
                            });
                        }
                        console.log(error);
                    });
            });
        });
    }

    onLeave(origin, destination, direction) {
        console.log("on leave")
    }

    afterLoad(origin, destination, direction) {
        console.log("after load")
    }

    closeForm() {
        this.setState({
            showForm: false,
            country: "155",
            password: "",
            passwordPopup: false,
            houseNumber: "",
            houseNumberPopup: false,
            firstName: "",
            firstNamePopup: false,
            street: "",
            streetPopup: false,
            lastName: "",
            lastNamePopup: false,
            zipCode: "",
            zipCodePopup: false,
            city: "",
            cityPopup: false,
            email: "",
            emailPopup: false,
            phoneNumber: "",
            phoneNumberPopup: false,
            agreed: false,
            agreedPopup: false,
            secondStep: false,
        })
    }

    logIn() {
        window.open("https://donatie.bhodi.com/p/login/8wM2aq0tu46N2wUfYY", "_blank")
    }

    // logIn() {
    //     const { loginEmail, loginPassword } = this.state;
    //     let regexEmail =
    //         /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (loginEmail === "" || !regexEmail.test(loginEmail)) {
    //         this.setState({ loginEmailPopup: true, loginEmail: "" });
    //     } else {
    //         this.setState({ loginEmailPopup: false });
    //     }
    //     if (loginPassword === "") {
    //         this.setState({ loginPasswordPopup: true });
    //     } else {
    //         this.setState({ loginPasswordPopup: false });
    //     }
    //     if (loginEmail !== "" && regexEmail.test(loginEmail) && loginPassword !== "") {
    //         this.setState({ aanmeldenloader: true });
    //         if (loginEmail === "admin@bhodi.com") {
    //             firebase
    //                 .auth()
    //                 .signInWithEmailAndPassword(loginEmail, loginPassword)
    //                 .then((succ) => {
    //                     firebase
    //                         .database()
    //                         .ref("users/" + succ.user.uid)
    //                         .on("value", (data) => {
    //                             localStorage.setItem("userid", succ.user.uid);
    //                             // localStorage.setItem("userdata", JSON.stringify(data.val()))
    //                             localStorage.setItem("isAdmin", true);
    //                             this.props.updateAdminOrNot(true);
    //                             localStorage.removeItem("onCurrentTab");
    //                             localStorage.removeItem("onCurrentTabCompany");
    //                             localStorage.removeItem("cookie_item");
    //                             setTimeout(() => {
    //                                 window.location.href = "/admin";
    //                             }, 400);
    //                         });
    //                 })
    //                 .catch((err) => {
    //                     if (err.code === "auth/user-not-found") {
    //                         this.setState({
    //                             aanmeldenloader: false,
    //                             loginEmailPopup: true,
    //                             loginEmail: "",
    //                             loginPassword: "",
    //                         });
    //                     } else if (err.code === "auth/wrong-password") {
    //                         this.setState({
    //                             aanmeldenloader: false,
    //                             loginPasswordPopup: true,
    //                             loginPassword: "",
    //                         });
    //                     } else {
    //                         this.setState({
    //                             aanmeldenloader: false
    //                         })
    //                         alert("Login failed, please try again")
    //                     }
    //                 });
    //         } else {
    //             firebase
    //                 .auth()
    //                 .signInWithEmailAndPassword(loginEmail, loginPassword)
    //                 .then((succ) => {
    //                     firebase
    //                         .database()
    //                         .ref("users/" + succ.user.uid)
    //                         .on("value", (data) => {
    //                             let a = data.val();
    //                             if (a) {
    //                                 if (a.type === "user") {
    //                                     localStorage.removeItem("onStoriesActive");
    //                                     this.props.updateAlbumToAdd("vrienden");
    //                                 }
    //                                 let promiseOne = new Promise((res) => {
    //                                     if (a.type === "user") {
    //                                         navigator.geolocation.getCurrentPosition((position) => {
    //                                             firebase
    //                                                 .database()
    //                                                 .ref("users/" + succ.user.uid + "/latitude")
    //                                                 .set(position.coords.latitude);
    //                                             firebase
    //                                                 .database()
    //                                                 .ref("users/" + succ.user.uid + "/longitude")
    //                                                 .set(position.coords.longitude);
    //                                             localStorage.setItem(
    //                                                 "latitudeAfterLogin",
    //                                                 position.coords.latitude
    //                                             );
    //                                             localStorage.setItem(
    //                                                 "longitudeAfterLogin",
    //                                                 position.coords.longitude
    //                                             );
    //                                             res();
    //                                         });
    //                                     } else {
    //                                         let address = `${a.street} ${a.houseNumber}, ${a.zipCode} ${a.city}, ${a.country}`;
    //                                         var config = {
    //                                             method: "get",
    //                                             url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
    //                                             headers: {},
    //                                         };

    //                                         axios(config)
    //                                             .then((response) => {
    //                                                 let coords = response.data.results[0].geometry.location;
    //                                                 firebase
    //                                                     .database()
    //                                                     .ref("users/" + a.user_id + "/latitude")
    //                                                     .set(coords.lat);
    //                                                 firebase
    //                                                     .database()
    //                                                     .ref("users/" + a.user_id + "/longitude")
    //                                                     .set(coords.lng);
    //                                             })
    //                                             .catch(function (error) {
    //                                                 console.log(error);
    //                                             });
    //                                         navigator.geolocation.getCurrentPosition((position) => {
    //                                             localStorage.setItem(
    //                                                 "latitudeAfterLogin",
    //                                                 position.coords.latitude
    //                                             );
    //                                             localStorage.setItem(
    //                                                 "longitudeAfterLogin",
    //                                                 position.coords.longitude
    //                                             );
    //                                             res();
    //                                         });
    //                                     }
    //                                     setTimeout(() => {
    //                                         res();
    //                                     }, 4000);
    //                                 });
    //                                 promiseOne.then(() => {
    //                                     localStorage.setItem("userid", succ.user.uid);
    //                                     // localStorage.setItem("userdata", JSON.stringify(a))
    //                                     localStorage.setItem("isAdmin", false);
    //                                     this.props.updateAdminOrNot(false);
    //                                     localStorage.removeItem("onCurrentTab");
    //                                     localStorage.removeItem("onCurrentTabCompany");
    //                                     localStorage.setItem("currentAlbumOwnImage", "vrienden");
    //                                     localStorage.setItem(
    //                                         "currentAlbumOwnImageFriends",
    //                                         "vrienden"
    //                                     );
    //                                     localStorage.removeItem("cookie_item");
    //                                     setTimeout(() => {
    //                                         if (a.type === "user") {
    //                                             window.location.href = `/member/${a.user_name_id}`;
    //                                             // if (window.innerWidth < 1009) {
    //                                             //     this.setState({ loader: false })
    //                                             // }
    //                                         } else {
    //                                             window.location.href = `/company/${a.user_name_id}`;
    //                                             // if (window.innerWidth < 1009) {
    //                                             //     this.setState({ loader: false })
    //                                             // }
    //                                         }
    //                                     }, 400);
    //                                 });
    //                             }
    //                         });
    //                 })
    //                 .catch((err) => {
    //                     if (err.code === "auth/user-not-found") {
    //                         this.setState({
    //                             aanmeldenloader: false,
    //                             loginEmailPopup: true,
    //                             loginEmail: "",
    //                             loginPassword: "",
    //                         });
    //                     } else if (err.code === "auth/wrong-password") {
    //                         this.setState({
    //                             aanmeldenloader: false,
    //                             loginPasswordPopup: true,
    //                             loginPassword: "",
    //                         });
    //                     } else {
    //                         this.setState({
    //                             aanmeldenloader: false
    //                         })
    //                         alert("Login failed, please try again")
    //                     }
    //                 });
    //         }
    //     }
    // }

    createMarkupCenteredDesc() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_desc) {
                return { __html: this.state.landing_page_new_desc };
            }
        } else {
            if (this.state.landing_page_new_desc_mobile) {
                return { __html: this.state.landing_page_new_desc_mobile };
            }
        }
    }

    createMarkupCenteredDescEnglish() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_desc_english) {
                return { __html: this.state.landing_page_new_desc_english };
            }
        } else {
            if (this.state.landing_page_new_desc_english_mobile) {
                return { __html: this.state.landing_page_new_desc_english_mobile };
            }
        }
    }

    createMarkupCenteredDate() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_date) {
                return { __html: this.state.landing_page_new_date };
            }
        } else {
            if (this.state.landing_page_new_date_mobile) {
                return { __html: this.state.landing_page_new_date_mobile };
            }
        }
    }

    createMarkupCenteredDateEnglish() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_date_english) {
                return { __html: this.state.landing_page_new_date_english };
            }
        } else {
            if (this.state.landing_page_new_date_english_mobile) {
                return { __html: this.state.landing_page_new_date_english_mobile };
            }
        }
    }

    createMarkupSpecialProductText() {
        if (window.innerWidth > 768) {
            if (this.state.special_products_text_dutch) {
                return { __html: this.state.special_products_text_dutch };
            }
        } else {
            if (this.state.special_products_text_dutch_mobile) {
                return { __html: this.state.special_products_text_dutch_mobile };
            }
        }
    }

    createMarkupSpecialProductTextEnglish() {
        if (window.innerWidth > 768) {
            if (this.state.special_products_text_english) {
                return { __html: this.state.special_products_text_english };
            }
        } else {
            if (this.state.special_products_text_english_mobile) {
                return { __html: this.state.special_products_text_english_mobile };
            }
        }
    }

    followCompany(currentUserId) {
        let pushFirst = firebase
            .database()
            .ref("users/" + currentUserId + "/following")
            .push().key;
        let pushSecond = firebase
            .database()
            .ref("users/" + this.props.mustFollowUser + "/followed_by")
            .push().key;
        let pushSecondNew = firebase
            .database()
            .ref(
                "users/" + this.props.mustFollowUser + "/newFollowersList"
            )
            .push().key;
        let newFollowers = 0;
        let obj = {
            user_id: this.props.mustFollowUser,
            push_key: pushFirst,
        };
        let objSec = {
            user_id: currentUserId,
            push_key: pushSecond,
        };
        let objThird = {
            user_id: currentUserId,
            push_key: pushSecondNew,
        };
        if (this.props.currentUserMember.newFollowers) {
            newFollowers = Number(this.props.currentUserMember.newFollowers);
        }
        newFollowers = newFollowers + 1;
        firebase
            .database()
            .ref("users/" + currentUserId + "/following/" + pushFirst)
            .set(obj);
        firebase
            .database()
            .ref(
                "users/" +
                this.props.mustFollowUser +
                "/followed_by/" +
                pushSecond
            )
            .set(objSec);
        firebase
            .database()
            .ref("users/" + this.props.mustFollowUser + "/newFollowers")
            .set(newFollowers);
        firebase
            .database()
            .ref(
                "users/" +
                this.props.mustFollowUser +
                "/newFollowersList/" +
                pushSecondNew
            )
            .set(objThird);
    }

    subscribeForExclusives() {
        let currentUserId = localStorage.getItem("userid")

        let plan_amount = ""
        let split_amount = ""
        let plan_id = ""
        let stripe_plan_key = ""

        if (this.state.subscriptionAmountProducts === "show_all" && !this.state.plan_amount_exclusive) {
            return false;
        }

        let filtered = this.state.subscription_data.filter((g) => {
            if (this.state.plan_amount_exclusive) {
                return g.price === this.state.plan_amount_exclusive
            } else {
                return g.price === this.state.subscriptionAmountProducts
            }
        })
        if (filtered.length > 0) {
            plan_amount = filtered[0].price
            plan_id = filtered[0].plan_id
            split_amount = filtered[0].split_amount
            stripe_plan_key = filtered[0].stripe_plan_key
        }


        if (this.props.user && plan_amount) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === currentUserId
            })

            if (filtered.length > 0) {
                this.setState({ aanmeldenloaderSubscribing: true });

                let onUserId = this.props.currentUserMember.user_id
                let pushKey = firebase.database().ref(`users/${currentUserId}/subscribedForExclusives`).push().key
                let pushKeySecond = firebase.database().ref(`users/${onUserId}/subscribersForExclusives`).push().key
                let obj = {
                    userId: onUserId,
                    pushKey,
                    payment_status: "pending",
                    added_at: Date.now(),
                    amount: plan_amount,
                }
                let objSecond = {
                    userId: currentUserId,
                    pushKey: pushKeySecond,
                    payment_status: "pending",
                    added_at: Date.now(),
                    amount: plan_amount,
                }
                firebase.database().ref(`users/${currentUserId}/subscribedForExclusives/${pushKey}`).set(obj)
                firebase.database().ref(`users/${onUserId}/subscribersForExclusives/${pushKeySecond}`).set(objSecond)


                if ((filtered[0].stripe_id && filtered[0].subscription && filtered[0].subscription.status === "active") || checkForSubscribedExclusives(filtered[0])) {
                    let pushKeyExclusive = firebase.database().ref(`exclusive_payees/${filtered[0].stripe_id}`).push().key
                    let objSubscribing = {
                        user_id_own: currentUserId,
                        user_id_subscribing: onUserId,
                        pushKeyOwnSubscribed: pushKey,
                        pushKeyOtherSubscribers: pushKeySecond,
                        stripe_id_own: filtered[0].stripe_id,
                        pushKeyOwn: pushKeyExclusive,
                    }
                    firebase.database().ref(`exclusive_payees/${filtered[0].stripe_id}/${pushKeyExclusive}`).set(objSubscribing)

                    let paymentObject = {
                        // amount: plan_amount,
                        customerId: filtered[0].stripe_id,
                        successUrl: filtered[0].type === "user" ? `${window.location.origin}/member/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}` : `${window.location.origin}/company/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}`,
                        cancelUrl: filtered[0].type === "user" ? `${window.location.origin}/member/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}` : `${window.location.origin}/company/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}`,
                        // exclusivePayment: true,
                        keyExclusivePayees: pushKeyExclusive,
                        priceId: stripe_plan_key,
                        customerExclusivePayeeId: filtered[0].stripe_id,
                        onlyExclusiveSubscribe: true,
                        isExclusiveSubscription: true
                    }
                    // if (this.state.organizationId) {
                    //     paymentObject.routeAmount = split_amount
                    //     paymentObject.orgId = this.state.organizationId
                    //     paymentObject.uid = currentUserId
                    //     paymentObject.description = `${this.props.currentUserMember.first_name + "" + this.props.currentUserMember.last_name}'s recurring payment`
                    // }

                    axios
                        .post(
                            "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCheckout",
                            paymentObject,
                        )
                        .then((responseSec) => {
                            this.setState({ aanmeldenloaderSubscribing: false });
                            window.location.href = responseSec.data.data.url;
                            // window.location.href = responseSec.data.payment._links.checkout.href;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    let currentTime = Math.floor(Date.now() / 1000);
                    let getRandom = this.randomStr(6);
                    let hashToken = getRandom + "" + currentTime;

                    let getStripeId = new Promise((res) => {
                        if (filtered[0].stripe_id) {
                            res(filtered[0].stripe_id)
                        } else {
                            axios
                                .post(
                                    "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCustomer",
                                    {
                                        fullName: filtered[0].first_name + "" + filtered[0].last_name,
                                        email: filtered[0].email,
                                    }
                                )
                                .then((response) => {
                                    res(response.data.data.id)
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                    getStripeId.then((stripeId) => {
                        let pushKeyExclusive = firebase.database().ref(`exclusive_payees/${stripeId}`).push().key
                        let objSubscribing = {
                            user_id_own: currentUserId,
                            user_id_subscribing: onUserId,
                            pushKeyOwnSubscribed: pushKey,
                            pushKeyOtherSubscribers: pushKeySecond,
                            stripe_id_own: stripeId,
                            pushKeyOwn: pushKeyExclusive,
                        }
                        firebase.database().ref(`exclusive_payees/${stripeId}/${pushKeyExclusive}`).set(objSubscribing)

                        let paymentObject = {
                            // amount: plan_amount,
                            customerId: stripeId,
                            successUrl: filtered[0].type === "user" ? `${window.location.origin}/member/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}` : `${window.location.origin}/company/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}`,
                            cancelUrl: filtered[0].type === "user" ? `${window.location.origin}/member/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}` : `${window.location.origin}/company/${filtered[0].user_name_id}/exclusive-subscribing?subscring=${onUserId}`,

                            // exclusivePaymentWithSubscription: true,
                            customerExclusivePayeeId: stripeId,
                            priceId: stripe_plan_key,
                            isExclusiveSubscription: true,
                            onlyExclusiveSubscribe: true,
                            keyExclusivePayees: pushKeyExclusive,
                        }
                        // if (this.state.organizationId) {
                        //     // paymentObject.isExclusiveSubscription = true;
                        //     // paymentObject.routeAmount = split_amount
                        //     // paymentObject.orgId = this.state.organizationId
                        //     // paymentObject.uid = currentUserId
                        //     // paymentObject.description = `${this.props.currentUserMember.first_name + "" + this.props.currentUserMember.last_name}'s recurring payment`

                        //     localStorage.setItem("doingPaymentOf", JSON.stringify(paymentObject))
                        // }


                        // let objSubscribing = {
                        //     user_id_own: currentUserId,
                        //     user_id_subscribing: onUserId,
                        //     pushKeyOwnSubscribed: pushKey,
                        //     pushKeyOtherSubscribers: pushKeySecond,
                        //     stripe_id_own: stripeId,
                        // }
                        // firebase.database().ref(`exclusive_payees/${stripeId}/`).set(objSubscribing)


                        axios.post(
                            "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCheckout",
                            // this.state.organizationId ? "https://us-central1-bhodi-production-da765.cloudfunctions.net/createSplitPayment" : "https://us-central1-bhodi-production-da765.cloudfunctions.net/createPayment",
                            // "https://us-central1-bhodi-production-da765.cloudfunctions.net/createPayment",
                            paymentObject,
                        )
                            .then((responseSec) => {

                                firebase.database().ref("users/" + currentUserId + "/stripe_id").set(stripeId);

                                // firebase.database().ref("users/" + currentUserId + "/is_canceled").remove();

                                // // if (this.state.organizationId) {
                                // //     firebase.database().ref("users/" + currentUserId + "/create_subscription_split").set(true);
                                // // } else {
                                // firebase.database().ref("users/" + currentUserId + "/create_subscription").set(true);
                                // // }

                                // firebase.database().ref("users/" + currentUserId + "/plan_id").set(plan_id);
                                // firebase.database().ref("users/" + currentUserId + "/plan_amount").set(plan_amount);
                                // firebase.database().ref("users/" + currentUserId + "/stripe_plan_key").set(stripe_plan_key);

                                // firebase.database().ref("users/" + currentUserId + "/payment_id").set(responseSec.data.data.id)
                                //     .then(() => {
                                this.setState({ aanmeldenloader: false });
                                window.location.href = responseSec.data.data.url;
                                // });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })

                }

            }

        }

    }

    sendResetLink() {
        const { loginEmail } = this.state;
        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (loginEmail === "" || !regexEmail.test(loginEmail)) {
            this.setState({ loginEmailPopup: true, loginEmail: "" });
        } else {
            this.setState({ loginEmailPopup: false });
        }
        if (loginEmail !== "" && regexEmail.test(loginEmail)) {
            this.setState({ aanmeldenloaderForgetPassword: true });

            var data = JSON.stringify({
                sendToEmail: loginEmail,
            });

            var config = {
                method: "post",
                url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendPasswordResetLinkUpdated",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then((response) => {
                    if (response.data.error) {
                        let errorCode = response.data.error.code;
                        if (
                            errorCode === "auth/email-not-found"
                        ) {
                            this.setState({ emailNotFound: true, aanmeldenloaderForgetPassword: false });
                        }
                    }
                    if (response.data.statusCode === 201) {
                        swal({
                            title: "Gelukt!",
                            text: "Binnen enkele minuten krijg je een e-mail met een link. Door op de link te klikken kun je een nieuw wachtwoord aanmaken.",
                            icon: "success",
                        }).then(() => {
                            this.setState({ aanmeldenloaderForgetPassword: false, showForgetPasswordPanel: false });
                        });
                    }
                })
                .catch((response) => {
                    var errorCode = response.error;
                    console.log(errorCode, '/see here')
                    // if (
                    //     errorCode === "auth/email-not-found"
                    // ) {
                    //     this.setState({ emailNotFound: true, aanmeldenloaderForgetPassword: false });
                    // }
                });
        }
    }

    createMarkupNew(e) {
        if (e) {
            return { __html: e };
        }
    }

    createMarkup(e) {
        if (e) {
            return { __html: e };
        }
    }

    renderFaqsPopup() {
        return (<div className='about-main-popup' style={{ opacity: this.state.showFaqsPopup ? 1 : 0, pointerEvents: this.state.showFaqsPopup ? "all" : "none" }}>
            <div className='abp-right-menu' style={{ right: this.state.showFaqsPopup ? 0 : "-100%" }}>
                <div
                    className="heading-mca"
                    style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                    }}
                >
                    <font>VRAGEN</font>
                    <div>
                        <ChevronLeft
                            onClick={() => {
                                this.setState({ showFaqsPopup: false });
                            }}
                            style={{
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                left: 10,
                                fontSize: 18,
                            }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                            onClick={() => {
                                this.setState({
                                    showFaqsPopup: false,
                                });
                            }}
                            style={{
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                right: 10,
                                fontSize: 18,
                            }}
                        />
                    </div>
                </div>
                <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                    <div className="form-body-mca">
                        <div className="inner-mca-settings">
                            <div
                                className="mca-settings-list"
                                style={{
                                    flexDirection: "column",
                                    backgroundColor: "transparent",
                                    width: "90%",
                                    margin: "10px auto",
                                }}
                            >
                                <div
                                    className="wrapperforfullwidth wrapperbhodifaqs"
                                    style={{ position: "relative" }}
                                >
                                    {this.state.bhodiFaqsGuests.length > 0 &&
                                        this.state.bhodiFaqsGuests.map((y, i) => {
                                            if (y.title) {
                                                return (
                                                    <div className="faq-inner-main">
                                                        <div
                                                            className="top-faq-rmcls"
                                                            onClick={() => {
                                                                if (y.desc) {
                                                                    this.state.arrDropdownsGuests[i].opened = !this.state.arrDropdownsGuests[i].opened;
                                                                    this.setState({
                                                                        arrDropdownsGuests: this.state.arrDropdownsGuests,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <p>{y.title}</p>
                                                            {this.state.arrDropdownsGuests[i].opened
                                                                ? y.desc && <RemoveIcon />
                                                                : y.desc && <AddIcon />}
                                                        </div>
                                                        {this.state.arrDropdownsGuests[i].opened && (
                                                            <div className="bottom-faq-rmcls">
                                                                <div dangerouslySetInnerHTML={this.createMarkup(y.desc)}></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    renderAboutPopup() {
        return (<div className='about-main-popup' style={{ opacity: this.state.contactOpen ? 1 : 0, pointerEvents: this.state.contactOpen ? "all" : "none" }}>
            <div className='abp-right-menu' style={{ right: this.state.contactOpen ? 0 : "-100%" }}>
                <CloseIcon onClick={() => this.setState({ contactOpen: false })} />
                <div className='aboutcont-bgimage abgimg-new'>
                </div>
                <div className='aboutinner-cont-pop'>
                    <h2>About</h2>
                    <p className='main-crm-black'>Bhodi</p>
                    <p>
                        Winde 18<br />
                        7623 NE Borne<br />
                        <br />
                        <br />
                        KVK:   70329346<br />
                        Bank:  NL56RABO 0328 7127 44<br />
                        Btw:   Bhodi is als social<br />
                        enterprise vrijgesteld van btw
                    </p>
                    <FormComponent />
                </div>
            </div>
        </div>)
    }

    render() {
        return (
            (this.props.atProductsPage && this.props.showExcLoader) ? (<div
                style={{
                    position: this.state.isDesktop && "fixed",
                    width: "100%",
                    height: "100vh",
                    top: 0,
                }}
                className="charitysectionmainwork"
            >
                <div
                    className="charityfullpageupdated"
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                {/* {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />} */}
            </div>) : this.state.showFormNewOne ? <SignupComponent alreadyInvitedCompanies={this.state.alreadyInvitedCompanies} alreadyInvitedCompaniesManual={this.state.alreadyInvitedCompaniesManual} subscribeAsCharityMember={(obj) => {
                this.moveToMollie(obj)
            }} createAccountOnly={(obj) => {
                this.createUserAccount(obj)
            }} closeScreen={() => {
                this.setState({ showFormNewOne: false })
            }} /> :
                (this.props.onSpecialProducts && !this.props.global_values) ? (<div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#d3d3d3" }}>
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>) : window.innerWidth > 1008 ? (
                    <div style={{ scrollBehavior: "smooth" }}>
                        <div className='landing-page-new' style={{ backgroundImage: `url("${this.props.onResortsPage ? "https://media.bhodi.com/bg.jpg" : (this.props.onBrandsPage && this.state.brands_page_banner_image) ? this.state.brands_page_banner_image : (this.props.onSpecialProducts && this.state.speical_products_page_banner_image) ? this.state.speical_products_page_banner_image : this.state.landing_page_media_type.includes("video") ? "" : this.state.landing_page_new_bg_media ? this.state.landing_page_new_bg_media : landingPageMainPic}")` }}>
                            {this.state.landing_page_media_type.includes("video") && (<video
                                src={this.state.landing_page_new_bg_media}
                                autoPlay
                                muted
                                loop
                                playsInline
                                className='video-fullpage-lpn'
                                onLoadedData={() => this.setState({ showText: true })}
                            ></video>)}
                            {/* {(!this.props.tiktokPage && !this.props.onResortsPage) && (<FooterBasket isSpecialProduct={true} />)} */}
                            {/* {this.props.onResortsPage ? (<></>) : !this.props.tiktokPage && (<button className='play-lbn-mainaanmelden' onClick={() => {
                                this.setState({ showFormNewOne: true })
                            }}>Aanmelden</button>)} */}
                            {(this.props.onProductsGuest && this.state.companyGuestStoryVideo) && (<video
                                src={this.state.companyGuestStoryVideo}
                                autoPlay
                                muted
                                loop
                                playsInline
                                className='video-fullpage-lpn'
                                onLoadedData={() => this.setState({ showText: true })}
                            ></video>)}
                            {this.state.showText && (<div className='centered-play-lpn' data-aos="zoom-in" style={{ opacity: !this.state.showForm ? 1 : 0 }}>
                                {this.state.companyGuestStoryTitle ? (<div className='logo-cglpn'>
                                    <h1>{this.state.companyGuestStoryTitle ? this.state.companyGuestStoryTitle : "BHODI"}</h1>
                                    <p>{this.state.companyGuestStoryDesc ? this.state.companyGuestStoryDesc : "LIFESTYLE"}</p>
                                </div>) : <div className='logo-cglpn'>
                                    <img src={(this.props.onBrandsPage && this.state.brands_page_top_logo) ? this.state.brands_page_top_logo : (this.props.onSpecialProducts && this.state.speical_products_page_top_logo) ? this.state.speical_products_page_top_logo : this.state.landing_page_new_logo_main ? this.state.landing_page_new_logo_main : logolandingpage} />
                                    {/* <h1>{this.state.companyGuestStoryTitle ? this.state.companyGuestStoryTitle : "BHODI"}</h1>
                            <p>{this.state.companyGuestStoryDesc ? this.state.companyGuestStoryDesc : "LIFESTYLE"}</p> */}
                                </div>}
                                <img src={(this.props.onBrandsPage && this.state.brands_page_play_icon) ? this.state.brands_page_play_icon : (this.props.onSpecialProducts && this.state.speical_products_page_play_icon) ? this.state.speical_products_page_play_icon : this.state.landing_page_new_logo_small ? this.state.landing_page_new_logo_small : playiconlandingpage} style={{ marginTop: 30 }} />
                                {this.state.companyGuestStoryInfo ? (<h3>{this.state.companyGuestStoryInfo}</h3>) : (<h3>
                                    <img src={this.props.onResortsPage ? resortspiclandingpage : (this.props.onBrandsPage && this.state.brands_page_play_picture) ? this.state.brands_page_play_picture : (this.props.onSpecialProducts && this.state.speical_products_page_play_picture) ? this.state.speical_products_page_play_picture : this.state.landing_page_new_logo_play ? this.state.landing_page_new_logo_play : playannouncementpic} />
                                    {/* {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupCenteredDesc()}></div> : <div dangerouslySetInnerHTML={this.createMarkupCenteredDescEnglish()}></div>} */}
                                    {/* play the announcement */}
                                </h3>)}
                                {this.props.onResortsPage ? (
                                    <p className='savedatelandingpage'>
                                        Beleef een ontspannen dagje wellness<br />
                                        bij de mooiste resorts van Nederland!
                                    </p>
                                ) : this.props.tiktokPage ? (
                                    <p className='savedatelandingpage'>
                                        Open de website van Bhodi in je browser.<br />
                                        Kopieer de link of klik op de 3 puntjes bovenaan.
                                    </p>
                                ) : this.props.onSpecialProducts ? (
                                    <p className='savedatelandingpage'>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupNew(this.state.brands_page_text_dutch)}></div> : <div dangerouslySetInnerHTML={this.createMarkupNew(this.state.brands_page_text_dutch_mobile)}></div>}
                                    </p>
                                ) : this.props.onSpecialProducts ? (
                                    <p className='savedatelandingpage'>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupSpecialProductText()}></div> : <div dangerouslySetInnerHTML={this.createMarkupSpecialProductTextEnglish()}></div>}
                                    </p>
                                ) : this.state.landing_page_new_dutch_description ? (<>
                                    {this.state.showDutch ? <p className='savedatelandingpage' dangerouslySetInnerHTML={this.createMarkupNew(this.state.landing_page_new_dutch_description)}></p> : <p className='savedatelandingpage' dangerouslySetInnerHTML={this.createMarkupNew(this.state.landing_page_new_english_description)}></p>}
                                </>) : (<p className='savedatelandingpage'>
                                    Word charity member en krijg toegang<br />
                                    tot exclusieve acties.
                                </p>)}
                                {/* <p className='savedatelandingpage'>save the date</p> */}
                                {/* {this.state.companyGuestStoryDate ? (<h5>
                                {this.state.companyGuestStoryDate}
                            </h5>) : (<h5>
                                {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupCenteredDate()}></div> : <div dangerouslySetInnerHTML={this.createMarkupCenteredDateEnglish()}></div>}
                            </h5>)} */}
                                {this.props.onResortsPage ? (<div className='container-bottom-lpn' style={{ justifyContent: "center" }}>
                                    <p>BELEEF</p>
                                    <p>|</p>
                                    <p>ERVAAR</p>
                                    <p>|</p>
                                    <p>GENIET</p>
                                </div>) : (<div className='container-bottom-lpn' style={{ justifyContent: "center" }}>
                                    {this.state.landing_page_new_dutch_point_one ? (<>
                                        <a href="#section-one-special">
                                            <p dangerouslySetInnerHTML={this.createMarkupNew(this.state.showDutch ? this.state.landing_page_new_dutch_point_one : this.state.landing_page_new_english_point_one)}></p>
                                        </a>
                                        <p>|</p>
                                        <a href="#section-two-special">
                                            <p dangerouslySetInnerHTML={this.createMarkupNew(this.state.showDutch ? this.state.landing_page_new_dutch_point_two : this.state.landing_page_new_english_point_two)}></p>
                                        </a>
                                        <p>|</p>
                                        <a href="#section-three-special">
                                            <p dangerouslySetInnerHTML={this.createMarkupNew(this.state.showDutch ? this.state.landing_page_new_dutch_point_three : this.state.landing_page_new_english_point_three)}></p>
                                        </a>
                                    </>) : (<>
                                        <p>{this.state.specialTextOneStoryGuest}</p>
                                        <p>|</p>
                                        <p>{this.state.specialTextTwoStoryGuest}</p>
                                        <p>|</p>
                                        <p>{this.state.specialTextThreeStoryGuest}</p>
                                        <p>|</p>
                                        <p>{this.state.specialTextFourthStoryGuest}</p>
                                    </>)}
                                </div>)}
                                {/* <div className='container-bottom-lpn'>
                            <p>FASHION</p>
                            <p>|</p>
                            <p>WATCHES</p>
                            <p>|</p>
                            <p>LIFESTYLE</p>
                            <p>|</p>
                            <p>FOOD</p>
                        </div> */}
                                {this.props.atProductsPage && this.state.subscriptionAmountProducts === "show_all" && (<div
                                    className="radios-charity-lfss"
                                    style={{ justifyContent: "center" }}
                                >
                                    {this.state.subscription_data.length > 0 &&
                                        [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                            if (!e.active) {
                                                return false;
                                            }
                                            return (
                                                <div className="inner-rclfss" key={Math.random()}>
                                                    <input
                                                        type="radio"
                                                        id="radiocharity"
                                                        name="charity"
                                                        value={e.price}
                                                        checked={
                                                            e.price ===
                                                            this.state.plan_amount_exclusive
                                                        }
                                                        onChange={() =>
                                                            this.setState({
                                                                plan_amount_exclusive: e.price,
                                                            })
                                                        }
                                                    />
                                                    <span>€ {Number(e.price)}</span>
                                                </div>
                                            );
                                        })}
                                </div>)}
                                {this.props.onResortsPage ? (<button onClick={() => {
                                    window.scrollTo(0, window.innerHeight)
                                }}>
                                    Ontdek
                                </button>) : this.props.tiktokPage ? (<button onClick={() => {
                                    var textField = document.createElement("textarea");
                                    textField.innerText = `${window.location.origin}/special-products`;
                                    document.body.appendChild(textField);
                                    textField.select();
                                    document.execCommand("copy");
                                    textField.remove();
                                    this.setState({ copiedTextDone: true })
                                    setTimeout(() => {
                                        this.setState({ copiedTextDone: false })
                                    }, 2000);
                                }}>
                                    Kopieer de link
                                </button>) : (this.props.onSpecialProducts || this.props.onBrandsPage) ? (<button onClick={() => {
                                    window.scrollTo(0, window.innerHeight)
                                    // this.props.moveToCharitySection()
                                }}>
                                    Bekijk
                                </button>) : this.props.atProductsPage ? (this.state.aanmeldenloaderSubscribing ? (
                                    <button>
                                        <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </button>
                                ) : <button onClick={() => {
                                    if (this.props.atProductsPage) {
                                        this.subscribeForExclusives()
                                    } else {
                                        this.setState({ showFormNewOne: true })
                                    }
                                }}>{this.state.companyGuestStoryButtonText ? this.state.companyGuestStoryButtonText : "Aanmelden"}</button>) : (<button onClick={() => {
                                    this.setState({ eventPopup: true })
                                }}>Play</button>)}
                            </div>)}
                            {this.state.secondStep ? (<div className='container-green-lpn' style={{ width: 383, paddingTop: 50, opacity: this.state.showForm ? 1 : 0, pointerEvents: this.state.showForm ? "all" : "none" }}>
                                <MdClose onClick={() => this.closeForm()} className="close-cglpn" />
                                <div className='logo-cglpn'>
                                    <h1>BHODI</h1>
                                </div>
                                <p style={{ color: "white", letterSpacing: 1, fontSize: 13 }}>
                                    Hoi <b>{this.state.firstName + " " + this.state.lastName}</b>
                                </p>
                                <p style={{ color: "white", letterSpacing: 1, fontSize: 13 }}>
                                    Word Charity Member en krijg toegang tot<br />
                                    exclusieve aanbiedingen op onze website.
                                    <br />
                                    <br />
                                    Als Charity Member ondersteun je kinderen<br />
                                    en scholen in Tanzania met maaltijden en<br />
                                    materialen. Van deze uitgifte ontvang je<br />
                                    een bedankvideo vanuit Tanzania.
                                    <br />
                                    <br />
                                    Je maandelijkse bijdrage kun je op ieder
                                    gewenst moment aanpassen of stopzetten
                                </p>
                                <div
                                    className="radios-charity-lfss"
                                // style={{ justifyContent: "center" }}
                                >
                                    {this.state.subscription_data.length > 0 &&
                                        ([...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                            if (!e.active) {
                                                return false;
                                            }
                                            return (
                                                <div className="inner-rclfss" key={Math.random()}>
                                                    <input
                                                        type="radio"
                                                        id="radiocharity"
                                                        name="charity"
                                                        value={e.plan_id}
                                                        checked={e.plan_id === this.state.plan_id}
                                                        onChange={() =>
                                                            this.setState({
                                                                plan_id: e.plan_id,
                                                                plan_amount: e.price,
                                                                stripe_plan_key: e.stripe_plan_key,
                                                            })
                                                        }
                                                    />
                                                    <span>€ {Number(e.price)}</span>
                                                </div>
                                            );
                                        }))}
                                </div>
                                <div className='container-button-cglpn'>
                                    <button style={{ marginRight: 10 }} onClick={() => {
                                        this.setState({ secondStep: false })
                                    }}>Vorige</button>
                                    {this.state.aanmeldenloader ? (
                                        <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    ) : (
                                        <button onClick={() => this.moveToMollie()}>
                                            Volgende
                                        </button>
                                    )}
                                    {(this.state.creatingAccountLoader && !this.props.userIdToRedirect) ? (
                                        <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    ) : !this.props.userIdToRedirect && (
                                        <button style={{ marginLeft: 10 }} onClick={() => this.createUserAccount()}>
                                            Overslaan
                                        </button>
                                    )}
                                    {/* <button style={{ marginLeft: 10 }} onClick={() => {
                                this.setState({ secondStep: false })
                            }}>Overslaan</button> */}
                                </div>
                            </div>) : !this.props.onSpecialProducts && (<div className='container-green-lpn' style={{ opacity: this.state.showForm ? 1 : 0, pointerEvents: this.state.showForm ? "all" : "none" }}>
                                <MdClose onClick={() => this.closeForm()} className="close-cglpn" />
                                <h2>AANMELDEN</h2>
                                <div className={`container-field-cglpn ${this.state.firstNamePopup ? "containerRedAlerCglpn" : ""}`}>
                                    <input
                                        placeholder="Voornaam"
                                        value={this.state.firstName}
                                        onChange={(e) =>
                                            this.setState({ firstName: e.target.value })
                                        }
                                    />
                                </div>
                                <div className={`container-field-cglpn ${this.state.lastNamePopup ? "containerRedAlerCglpn" : ""}`}>
                                    <input
                                        placeholder="Achternaam"
                                        value={this.state.lastName}
                                        onChange={(e) =>
                                            this.setState({ lastName: e.target.value })
                                        }
                                    />
                                </div>
                                <form style={{ width: "100%" }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                    <div className={`container-field-cglpn ${this.state.emailPopup ? "containerRedAlerCglpn" : ""}`}>
                                        <input
                                            type="email"
                                            placeholder="E-mail"
                                            autoComplete="new-password"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ email: e.target.value })}
                                        />
                                    </div>
                                    {this.state.emailTookedPopup && (
                                        <div className="alert-field">
                                            <span style={{ letterSpacing: 1 }}>
                                                Email already taken
                                            </span>
                                        </div>
                                    )}
                                    <div className={`container-field-cglpn ${this.state.passwordPopup ? "containerRedAlerCglpn" : ""}`}>
                                        <input
                                            type="password"
                                            autoComplete="new-password"
                                            placeholder="Kies wachtwoord"
                                            value={this.state.password}
                                            onChange={(e) => {
                                                this.setState({ password: e.target.value });
                                            }}
                                        />
                                    </div>
                                </form>
                                {!this.state.isUserForm && (<div className={`container-field-cglpn ${this.state.companyNamePopup ? "containerRedAlerCglpn" : ""}`}>
                                    <input
                                        placeholder="Bedrijfsnaam"
                                        value={this.state.companyName}
                                        onChange={(e) =>
                                            this.setState({ companyName: e.target.value })
                                        }
                                    />
                                </div>)}
                                {/* <div className={`container-field-cglpn selectcountry-cfglpn`}> */}
                                <div className="form-field-clgec switchformclgec">
                                    {this.state.isUserForm ? (
                                        <label style={{ color: "white" }}>Consument</label>
                                    ) : (
                                        <label style={{ color: "white" }}>Zakelijk</label>
                                    )}
                                    <Switch
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                        checked={this.state.isUserForm}
                                        onClick={() => {
                                            this.setState({
                                                isUserForm: !this.state.isUserForm,
                                            });
                                        }}
                                    />
                                </div>
                                {/* </div> */}
                                <div className={`container-field-cglpn selectcountry-cfglpn`}>
                                    <select
                                        value={this.state.country}
                                        onChange={(e) => {
                                            this.setState({ country: e.target.value });
                                        }}
                                    >
                                        {countries.map((e) => {
                                            return <option value={e.id} key={Math.random()}>{e.name}</option>;
                                        })}
                                    </select>
                                    <KeyboardArrowDownIcon />
                                </div>
                                <div className={`container-field-cglpn ${this.state.cityPopup ? "containerRedAlerCglpn" : ""} citypickercontainer`}>
                                    {/* <input
                                type="email"
                                placeholder="City"
                                autoComplete="off"
                                value={this.state.city}
                                onChange={(e) => this.setState({ city: e.target.value })}
                            /> */}
                                    <GooglePlacesAutocomplete
                                        apiKey="AIzaSyBing0s1IsYV0GaPy2Fu513--sq5EPGLFg"
                                        autocompletionRequest={{ types: ["locality"] }}
                                        selectProps={{
                                            value: this.state.checkCity,
                                            placeholder: "Plaats",
                                            onChange: (val) => {
                                                this.setState({ checkCity: val });
                                                geocodeByPlaceId(val.value.place_id)
                                                    .then((results) => {
                                                        let filtered = results[0].address_components.filter(
                                                            (e) => {
                                                                return (
                                                                    e.types[0] === "locality" &&
                                                                    e.types[1] === "political"
                                                                );
                                                            }
                                                        );
                                                        this.setState({ city: filtered[0].long_name })
                                                    })
                                                    .catch((error) => console.error(error));
                                            },
                                        }}
                                    />
                                </div>
                                {this.state.cityPopup && (
                                    <div className="alert-field">
                                        <span style={{ letterSpacing: 1 }}>
                                            Please select city
                                        </span>
                                    </div>
                                )}
                                <div
                                    className="fx-registration-inner"
                                    style={{ alignItems: "center" }}
                                >
                                    <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                        <div
                                            className="left-frvn"
                                            style={{
                                                width: "100%",
                                                paddingLeft: 0,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <div className="checking-box" style={{ width: "100%" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.agreed}
                                                    onChange={() =>
                                                        this.setState({ agreed: !this.state.agreed })
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        color: this.state.agreedPopup ? "red" : "white",
                                                        fontSize: 13, width: "100%"
                                                    }}
                                                >
                                                    <font
                                                        style={{
                                                            // textDecorationLine: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                                "_blank" // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Akkoord met voorwaarden
                                                    </font>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container-button-cglpn'>
                                    {this.state.aanmeldenloader ? (
                                        <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    ) : <button onClick={() => {
                                        if (this.props.onProductsGuest && this.state.subscriptionAmountProducts !== "show_all") {
                                            let filtered = this.state.subscription_data.filter((g) => {
                                                return g.price === this.state.subscriptionAmountProducts
                                            })

                                            if (filtered.length > 0) {
                                                this.setState({
                                                    plan_id: filtered[0].plan_id,
                                                    plan_amount: filtered[0].price,
                                                    stripe_plan_key: filtered[0].stripe_plan_key,
                                                })

                                                setTimeout(() => {
                                                    this.moveToMollie()
                                                }, 1000);
                                            }
                                            return false;
                                        }
                                        this.moveToSecondStep()
                                    }}>Volgende</button>}
                                </div>
                            </div>)}
                            {!this.props.tiktokPage && (<div className='center-bottom-main-lpn' onClick={() => {
                                window.scrollTo(0, window.innerHeight)
                            }}>
                                <div className="mouse"></div>
                                {/* <UnfoldMore /> */}
                            </div>)}
                            <button className="top-absolute-copied-landingpage" style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Gekopieerd</button>
                            {(!this.props.mustFollowUser && !this.props.tiktokPage && !this.props.onResortsPage) && (<div className='container-bottom-lpn'>
                                {/* <p style={{ cursor: "pointer" }} onClick={() => this.setState({ storyOpen: true })}>story</p>
                                <p>|</p> */}
                                {!this.props.onResortsPage && (<>
                                    <p style={{ cursor: "pointer" }} onClick={() => {
                                        if (this.props.moveToCharitySection) {
                                            this.props.moveToCharitySection()
                                        } else {
                                            window.scrollTo(0, window.innerHeight)
                                        }
                                    }}>about</p>
                                    <p>|</p>
                                </>)}
                                <GuestFaqComponent openPopup={(bhodiFaqsGuests, arrDropdownsGuests) => {
                                    this.setState({ bhodiFaqsGuests, arrDropdownsGuests, showFaqsPopup: true })
                                }} />
                                <p style={{ cursor: "pointer" }} onClick={() => this.setState({ contactOpen: true })}>contact</p>
                                <p>|</p>
                                <p
                                    onClick={() => {
                                        window.open(
                                            "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                            "_blank" // <- This is what makes it open in a new window.
                                        );
                                    }} style={{ cursor: "pointer" }}>voorwaarden</p>
                                {!this.state.secondStep && (<p>|</p>)}
                                {!this.state.secondStep && (<BsPersonFill style={{ color: "white", fontSize: 18 }} onClick={() => {
                                    this.setState({ showLoginPanel: true })
                                }} />)}
                            </div>)}
                            <div className='login-overlay-main lom-new-text' style={{ opacity: this.state.showLoginPanel ? 1 : 0, pointerEvents: this.state.showLoginPanel ? "all" : "none", zIndex: 9999999999 }}>
                                <div className='login-container-lpn'>
                                    <MdClose className='icon-person-top-lpn' onClick={() => {
                                        this.setState({ showLoginPanel: false })
                                    }} />
                                    <h2>LOGIN</h2>
                                    {/* <form style={{ width: "100%" }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <div className={`container-field-cglpn ${this.state.loginEmailPopup ? "containerRedAlerCglpn" : ""}`}>
                                            <input type="email" autoComplete='new-password' placeholder='E-mail' value={this.state.loginEmail} onChange={(e) => {
                                                this.setState({ loginEmail: e.target.value })
                                            }} />
                                        </div>
                                        <div className={`container-field-cglpn ${this.state.loginPasswordPopup ? "containerRedAlerCglpn" : ""}`}>
                                            <input type="password" autoComplete='new-password' placeholder='Wachtwoord' value={this.state.loginPassword} onChange={(e) => {
                                                this.setState({ loginPassword: e.target.value })
                                            }} />
                                        </div>
                                    </form> */}
                                    <p>Beheer eenvoudig je<br />gegevens en je bijdrage.</p>
                                    <div className='container-button-cglpn'>
                                        {this.state.aanmeldenloader ? (
                                            <div className="lds-ellipsis">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        ) : (<button onClick={() => this.logIn()} style={{ borderRadius: 6 }}>Klik hier</button>)}
                                        {/* ) : (<button onClick={() => this.logIn()} style={{ borderRadius: 6 }}>Inloggen</button>)} */}
                                    </div>
                                    {/* <label className='forget-pwd-lpn' onClick={() => {
                                        this.setState({ showLoginPanel: false, showForgetPasswordPanel: true })
                                    }}>Wachtwoord vergeten?</label> */}
                                </div>
                            </div>
                            <div className='login-overlay-main' style={{ opacity: this.state.showForgetPasswordPanel ? 1 : 0, pointerEvents: this.state.showForgetPasswordPanel ? "all" : "none", zIndex: 99999999999 }}>
                                <div className='login-container-lpn'>
                                    <MdClose className='icon-person-top-lpn' onClick={() => {
                                        this.setState({ showForgetPasswordPanel: false })
                                    }} />
                                    <h2>FORGET PASSWORD</h2>
                                    {this.state.showForgetPasswordPanel && (<form style={{ width: "100%" }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                        <div className={`container-field-cglpn ${this.state.loginEmailPopup ? "containerRedAlerCglpn" : ""}`}>
                                            <input type="email" autoComplete='new-password' placeholder='E-mail' value={this.state.loginEmail} onChange={(e) => {
                                                this.setState({ loginEmail: e.target.value })
                                            }} />
                                            {this.state.emailNotFound && (
                                                <div className="alert-field" style={{ width: "100%" }}>
                                                    <span style={{ letterSpacing: 1 }}>
                                                        Email not founded in our records
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </form>)}
                                    <div className='container-button-cglpn'>
                                        {this.state.aanmeldenloaderForgetPassword ? (
                                            <div className="lds-ellipsis">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        ) : (<button onClick={() => this.sendResetLink()} style={{ borderRadius: 6 }}>Send</button>)}
                                    </div>
                                    <label className='forget-pwd-lpn' onClick={() => {
                                        this.setState({ showLoginPanel: true, showForgetPasswordPanel: false })
                                    }}>Login?</label>
                                </div>
                            </div>
                            {/* {this.state.storyOpen && (<div className='complete-video-lpn'>
                                <div className='inner-cont-cvlpn' style={{ backgroundColor: "black" }}>
                                    <MdClose className='icon-person-top-lpn' style={{ color: "white" }} onClick={() => {
                                        this.setState({ storyOpen: false })
                                    }} />
                                    <video
                                        src="https://video.bhodi.com/Bhodi.mp4"
                                        // src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/Bhodi-video.mp4?alt=media&token=d42c20a1-1944-4b79-b7bb-4ea317a7a69e&_gl=1*13idzsb*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5ODIyOTU1MC40NjYuMS4xNjk4MjMxNDQ1LjU1LjAuMA."
                                        autoPlay
                                        loop
                                        playsInline
                                        controls
                                    ></video>
                                </div>
                            </div>)} */}
                            {this.state.eventPopup && (<div className='complete-video-lpn'>
                                <div className='inner-cont-cvlpn' style={{ backgroundColor: "black" }}>
                                    <MdClose className='icon-person-top-lpn' style={{ color: "white" }} onClick={() => {
                                        this.setState({ eventPopup: false })
                                    }} />
                                    <video
                                        src="https://video.bhodi.com/Event.mp4"
                                        // src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/Bhodi-video.mp4?alt=media&token=d42c20a1-1944-4b79-b7bb-4ea317a7a69e&_gl=1*13idzsb*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5ODIyOTU1MC40NjYuMS4xNjk4MjMxNDQ1LjU1LjAuMA."
                                        autoPlay
                                        loop
                                        playsInline
                                        controls
                                    ></video>
                                </div>
                            </div>)}
                            {window.innerWidth > 1008 && <Footer notOnMainPage={this.props.atProductsPage ? false : true} />}
                            {window.innerWidth < 1008 && <FooterGreen notOnMainPage={this.props.atProductsPage ? false : true} />}


                            {this.state.aboutOpen && <NewStoryPopup closePopup={() => {
                                this.setState({ aboutOpen: false })
                            }} />}
                            {this.renderAboutPopup()}
                            {this.renderFaqsPopup()}
                        </div>
                        {/* {this.state.company_on_landing_page && (<NewProductWrapper toShowProductOf={this.state.company_on_landing_page} onGuestUserPage={true} />)} */}
                        {this.state.currentCampaignToShow && this.state.currentCampaignToShow.active && (
                            <div ref={this.sectionRef}>
                                <div className='top-events-section'>
                                    <p>Event</p>
                                    <p>{this.state.currentCampaignToShow.title}</p>
                                </div>
                                <EventsNewSection
                                    campaignToShow={[this.state.currentCampaignToShow]}
                                    onEditOpen={() => {
                                        document.querySelector("html").style.overflowY = "hidden"
                                        this.setState({
                                            openAddPopup: true,
                                            doEditLocalEvent: true,
                                        });
                                    }}
                                    hideBookMarkIcon={true}
                                />
                            </div>
                        )}
                        {(!this.props.onSpecialProducts && !this.props.onResortsPage) && (<CharityLandingPage dontshowMenu={true} onLandingPage={true} />)}
                    </div>
                ) : (
                    <div>
                        {/* <ReactFullpage
                        onLeave={this.onLeave.bind(this)}
                        afterLoad={this.afterLoad.bind(this)}
                        normalScrollElements=".posts-detailed-popup, .charity-confirmation-complete, .loaderareaduplicate, .bg-overlaymini, .mnicart-flyout-inner, .rightmenucompletewidthaddproduct, .rightmenucommentsaddproduct"
                        render={({ state, fullpageApi }) => {
                            return (
                                <div>
                                    <div className='section' id="sectionnotheight">
                                        <div className={`firstsection-lpnmob ${this.props.atProductsPage ? "firstsectionsomepadding" : null}`} style={{ backgroundImage: `url("${landingPageMainPic}")` }}>
                                            {!this.state.menuOpen && (<div
                                                className="top-icon-home"
                                            >
                                                <BsPersonFill onClick={() => {
                                                    this.setState({ showLoginPanel: true })
                                                }} />
                                                <MenuIcon
                                                    className="fontsizealwaysbiggermenu"
                                                    onClick={() => this.setState({ menuOpen: true })}
                                                />
                                            </div>)}


                                            <div
                                                className={`mymenu mcipm-updated menuchangeintopopupmobile ${this.state.menuOpen && "menumobileopen"
                                                    }`}
                                                id="navbarSupportedContent"
                                            >
                                                <ul className="navbar-nav mr-auto slideractive" id="menu">
                                                    {!this.state.isDesktop && (
                                                        <CloseIcon
                                                            className="closeiconhomenav"
                                                            onClick={() => {
                                                                document.getElementById("root").style.position = "static";
                                                                this.setState({ menuOpen: false });
                                                            }}
                                                        />
                                                    )}
                                                    <li className="nav-item" onClick={() => this.setState({ storyOpen: true, menuOpen: false })}>
                                                        <a className="nav-link">Story</a>
                                                    </li>
                                                    <li className="nav-item" onClick={() => {
                                                        history.push("/bhodi-about")
                                                    }}>
                                                        <a className="nav-link">About</a>
                                                    </li>
                                                    <li className="nav-item" onClick={() => this.setState({ contactOpen: true, menuOpen: false })}>
                                                        <a className="nav-link">Contact</a>
                                                    </li>
                                                    <li className="nav-item" onClick={() => this.setState({ menuOpen: false })}>
                                                        <a className="nav-link" href="https://media.bhodi.com/gebruikersvoorwaarden.pdf" target='_blank'>Voorwaarden</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <video
                                                src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."
                                                autoPlay
                                                muted
                                                data-keepplaying
                                                loop
                                                playsInline
                                                className='video-fullpage-lpn'
                                                onLoadedData={() => this.setState({ showText: true })}
                                            ></video>
                                            {(this.props.onProductsGuest && this.state.companyGuestStoryVideo) && (<video
                                                src={this.state.companyGuestStoryVideo}
                                                autoPlay
                                                muted
                                                data-keepplaying
                                                loop
                                                playsInline
                                                className='video-fullpage-lpn'
                                                onLoadedData={() => this.setState({ showText: true })}
                                            ></video>)}
                                            {this.state.showText && (<div className='centered-play-lpn'>
                                                <div className='logo-cglpn'>
                                                    <img src={logolandingpagemobile} style={{ maxWidth: 160 }} />
                                                </div>
                                                <img src={playiconlandingpagemobile} style={{ marginTop: 30, maxWidth: 30 }} />
                                                {this.state.companyGuestStoryInfo ? (<h3>{this.state.companyGuestStoryInfo}</h3>) : (<h3>
                                                    <img src={playannouncementpicmobile} style={{ maxWidth: "80%" }} />
                                                </h3>)}
                                                <p className='savedatelandingpage'>Bhodi charity menu</p>
                                                {this.state.companyGuestStoryDate ? (<h5>
                                                    {this.state.companyGuestStoryDate}
                                                </h5>) : (<h5>
                                                    {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupCenteredDate()}></div> : <div dangerouslySetInnerHTML={this.createMarkupCenteredDateEnglish()}></div>}
                                                </h5>)}
                                                <div className='container-bottom-lpn' style={{ justifyContent: "center" }}>
                                                    <p>{this.state.specialTextOneStoryGuest}</p>
                                                    <p>|</p>
                                                    <p>{this.state.specialTextTwoStoryGuest}</p>
                                                    <p>|</p>
                                                    <p>{this.state.specialTextThreeStoryGuest}</p>
                                                    <p>|</p>
                                                    <p>{this.state.specialTextFourthStoryGuest}</p>
                                                </div>
                                                {this.props.atProductsPage && this.state.subscriptionAmountProducts === "show_all" && (<div
                                                    className="radios-charity-lfss"
                                                    style={{ justifyContent: "center" }}
                                                >
                                                    {this.state.subscription_data.length > 0 &&
                                                        [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                                            return (
                                                                <div className="inner-rclfss" key={Math.random()}>
                                                                    <input
                                                                        type="radio"
                                                                        id="radiocharity"
                                                                        name="charity"
                                                                        value={e.price}
                                                                        checked={
                                                                            e.price ===
                                                                            this.state.plan_amount_exclusive
                                                                        }
                                                                        onChange={() =>
                                                                            this.setState({
                                                                                plan_amount_exclusive: e.price,
                                                                            })
                                                                        }
                                                                    />
                                                                    <span>€ {Number(e.price)}</span>
                                                                </div>
                                                            );
                                                        })}
                                                </div>)}
                                                {this.props.atProductsPage ? (this.state.aanmeldenloaderSubscribing ? (
                                                    <button>
                                                        <div className="lds-ellipsis">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </button>
                                                ) : <button onClick={() => {
                                                    if (this.props.atProductsPage) {
                                                        this.subscribeForExclusives()
                                                    } else {
                                                        this.setState({ showFormNewOne: true })
                                                    }
                                                }}>{this.state.companyGuestStoryButtonText ? this.state.companyGuestStoryButtonText : "Aanmelden"}</button>) : (<button onClick={() => {
                                                    // fullpageApi.moveTo(2, 0)
                                                    this.setState({ showFormNewOne: true })
                                                }}>Aanmelden</button>)}
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    /> */}
                        <div style={{ height: `${window.innerHeight}px` }}>
                            <div className={`firstsection-lpnmob ${this.props.atProductsPage ? "firstsectionsomepadding" : null}`} style={{ backgroundImage: `url("${this.props.onResortsPage ? "https://media.bhodi.com/bg.jpg" : (this.props.onBrandsPage && this.state.brands_page_banner_image) ? this.state.brands_page_banner_image : (this.props.onSpecialProducts && this.state.speical_products_page_banner_image) ? this.state.speical_products_page_banner_image : this.state.landing_page_media_type_mobile.includes("video") ? "" : this.state.landing_page_new_bg_media_mobile ? this.state.landing_page_new_bg_media_mobile : landingPageMainPic}")` }}>
                                {!this.props.tiktokPage && <>
                                    {/* {!this.props.onResortsPage && (<FooterBasket isSpecialProduct={true} />)} */}
                                    {!this.state.menuOpen && (<div
                                        className="top-icon-home"
                                    >
                                        <BsPersonFill onClick={() => {
                                            this.setState({ showLoginPanel: true })
                                        }} />
                                        <MenuIcon
                                            className="fontsizealwaysbiggermenu"
                                            onClick={() => this.setState({ menuOpen: true })}
                                        />
                                    </div>)}
                                </>}

                                <div
                                    className={`mymenu mcipm-updated menuchangeintopopupmobile ${this.state.menuOpen && "menumobileopen"
                                        }`}
                                    id="navbarSupportedContent"
                                >
                                    <ul className="navbar-nav mr-auto slideractive" id="menu">
                                        {!this.state.isDesktop && (
                                            <CloseIcon
                                                className="closeiconhomenav"
                                                onClick={() => {
                                                    document.getElementById("root").style.position = "static";
                                                    this.setState({ menuOpen: false });
                                                }}
                                            />
                                        )}
                                        {/* <li className="nav-item" onClick={() => this.setState({ storyOpen: true, menuOpen: false })}>
                                            <a className="nav-link">Story</a>
                                        </li> */}
                                        {!this.props.onResortsPage && (<li className="nav-item" onClick={() => {
                                            if (this.props.moveToCharitySection) {
                                                this.setState({ menuOpen: false })
                                                this.props.moveToCharitySection()
                                            } else {
                                                history.push("/bhodi-about")
                                            }
                                        }}>
                                            <a className="nav-link">About</a>
                                        </li>)}
                                        <GuestFaqComponent openPopup={(bhodiFaqsGuests, arrDropdownsGuests) => {
                                            this.setState({ bhodiFaqsGuests, arrDropdownsGuests, showFaqsPopup: true, menuOpen: false })
                                        }} />
                                        <li className="nav-item" onClick={() => this.setState({ contactOpen: true, menuOpen: false })}>
                                            <a className="nav-link">Contact</a>
                                        </li>
                                        <li className="nav-item" onClick={() => this.setState({ menuOpen: false })}>
                                            <a className="nav-link" href="https://media.bhodi.com/gebruikersvoorwaarden.pdf" target='_blank'>Voorwaarden</a>
                                        </li>
                                    </ul>
                                </div>

                                {this.state.landing_page_media_type_mobile.includes("video") && (<video
                                    src={this.state.landing_page_new_bg_media_mobile}
                                    autoPlay
                                    muted
                                    data-keepplaying
                                    loop
                                    playsInline
                                    className='video-fullpage-lpn'
                                    onLoadedData={() => this.setState({ showText: true })}
                                ></video>)}
                                {(this.props.onProductsGuest && this.state.companyGuestStoryVideo) && (<video
                                    src={this.state.companyGuestStoryVideo}
                                    autoPlay
                                    muted
                                    data-keepplaying
                                    loop
                                    playsInline
                                    className='video-fullpage-lpn'
                                    onLoadedData={() => this.setState({ showText: true })}
                                ></video>)}
                                {this.state.showText && (<div className='centered-play-lpn'>
                                    <div className='logo-cglpn'>
                                        <img src={(this.props.onBrandsPage && this.state.brands_page_top_logo_mobile) ? this.state.brands_page_top_logo_mobile : (this.props.onSpecialProducts && this.state.speical_products_page_top_logo_mobile) ? this.state.speical_products_page_top_logo_mobile : this.state.landing_page_new_logo_main_mobile ? this.state.landing_page_new_logo_main_mobile : logolandingpagemobile} style={{ maxWidth: 120 }} />
                                    </div>
                                    <img src={(this.props.onBrandsPage && this.state.brands_page_play_icon_mobile) ? this.state.brands_page_play_icon_mobile : (this.props.onSpecialProducts && this.state.speical_products_page_play_icon_mobile) ? this.state.speical_products_page_play_icon_mobile : this.state.landing_page_new_logo_small_mobile ? this.state.landing_page_new_logo_small_mobile : playiconlandingpagemobile} style={{ marginTop: 30, maxWidth: 30 }} />
                                    {this.state.companyGuestStoryInfo ? (<h3>{this.state.companyGuestStoryInfo}</h3>) : (<h3>
                                        <img src={this.props.onResortsPage ? resortspiclandingpagemobile : (this.props.onBrandsPage && this.state.brands_page_play_picture_mobile) ? this.state.brands_page_play_picture_mobile : (this.props.onSpecialProducts && this.state.speical_products_page_play_picture_mobile) ? this.state.speical_products_page_play_picture_mobile : this.state.landing_page_new_logo_play_mobile ? this.state.landing_page_new_logo_play_mobile : playannouncementpicmobile} style={{ maxWidth: "80%" }} />
                                    </h3>)}

                                    {this.props.onResortsPage ? (
                                        <p className='savedatelandingpage'>
                                            Beleef een ontspannen dagje wellness<br />
                                            bij de mooiste resorts van Nederland!
                                        </p>
                                    ) : this.props.tiktokPage ? (
                                        <p className='savedatelandingpage'>
                                            Open de website van Bhodi in je browser.<br />
                                            Kopieer de link of klik op de 3 puntjes bovenaan.
                                        </p>
                                    ) : this.props.onBrandsPage ? (
                                        <p className='savedatelandingpage'>
                                            {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupNew(this.state.brands_page_text_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkupNew(this.state.brands_page_text_english_mobile)}></div>}
                                        </p>
                                    ) : this.props.onSpecialProducts ? (
                                        <p className='savedatelandingpage'>
                                            {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupSpecialProductText()}></div> : <div dangerouslySetInnerHTML={this.createMarkupSpecialProductTextEnglish()}></div>}
                                        </p>
                                    ) : this.state.landing_page_new_dutch_description_mobile ? (<>
                                        {this.state.showDutch ? <p className='savedatelandingpage' dangerouslySetInnerHTML={this.createMarkupNew(this.state.landing_page_new_dutch_description_mobile)}></p> : <p className='savedatelandingpage' dangerouslySetInnerHTML={this.createMarkupNew(this.state.landing_page_new_english_description_mobile)}></p>}
                                    </>) : (<p className='savedatelandingpage'>
                                        Word charity member en krijg toegang tot exclusieve acties.
                                    </p>)}
                                    {/* <p className='savedatelandingpage'>
                                    Word charity member en krijg toegang tot exclusieve acties en gratis fashion & lifestyle items.
                                </p> */}
                                    {/* <p className='savedatelandingpage'>save the date</p> */}
                                    {/* {this.state.companyGuestStoryDate ? (<h5>
                                    {this.state.companyGuestStoryDate}
                                </h5>) : (<h5>
                                    {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkupCenteredDate()}></div> : <div dangerouslySetInnerHTML={this.createMarkupCenteredDateEnglish()}></div>}
                                </h5>)} */}
                                    {!this.props.tiktokPage && (<div className='center-bottom-main-lpn' onClick={() => {
                                        window.scrollTo(0, window.innerHeight)
                                    }}>
                                        <div className="mouse"></div>
                                    </div>)}
                                    {this.props.tiktokPage && (<div className='top-right-tiktok'>
                                        <div className="mouse-arrow"></div>
                                    </div>)}
                                    <button className="top-absolute-copied-landingpage" style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Gekopieerd</button>
                                    {this.props.onResortsPage ? (<div className='container-bottom-lpn' style={{ justifyContent: "center" }}>
                                        <p>BELEEF</p>
                                        <p>|</p>
                                        <p>ERVAAR</p>
                                        <p>|</p>
                                        <p>GENIET</p>
                                    </div>) : (<div className='container-bottom-lpn' style={{ justifyContent: "center" }}>
                                        {this.state.landing_page_new_dutch_point_one_mobile ? (<>
                                            <a href="#section-one-special">
                                                <p dangerouslySetInnerHTML={this.createMarkupNew(this.state.showDutch ? this.state.landing_page_new_dutch_point_one_mobile : this.state.landing_page_new_english_point_one_mobile)}></p>
                                            </a>
                                            <p>|</p>
                                            <a href="#section-two-special">
                                                <p dangerouslySetInnerHTML={this.createMarkupNew(this.state.showDutch ? this.state.landing_page_new_dutch_point_two_mobile : this.state.landing_page_new_english_point_two_mobile)}></p>
                                            </a>
                                            <p>|</p>
                                            <a href="#section-three-special">
                                                <p dangerouslySetInnerHTML={this.createMarkupNew(this.state.showDutch ? this.state.landing_page_new_dutch_point_three_mobile : this.state.landing_page_new_english_point_three_mobile)}></p>
                                            </a>
                                        </>) : (<>
                                            <p>{this.state.specialTextOneStoryGuest}</p>
                                            <p>|</p>
                                            <p>{this.state.specialTextTwoStoryGuest}</p>
                                            <p>|</p>
                                            <p>{this.state.specialTextThreeStoryGuest}</p>
                                            <p>|</p>
                                            <p>{this.state.specialTextFourthStoryGuest}</p>
                                        </>)}
                                    </div>)}
                                    {this.props.atProductsPage && this.state.subscriptionAmountProducts === "show_all" && (<div
                                        className="radios-charity-lfss"
                                        style={{ justifyContent: "center" }}
                                    >
                                        {this.state.subscription_data.length > 0 &&
                                            [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                                if (!e.active) {
                                                    return false;
                                                }
                                                return (
                                                    <div className="inner-rclfss" key={Math.random()}>
                                                        <input
                                                            type="radio"
                                                            id="radiocharity"
                                                            name="charity"
                                                            value={e.price}
                                                            checked={
                                                                e.price ===
                                                                this.state.plan_amount_exclusive
                                                            }
                                                            onChange={() =>
                                                                this.setState({
                                                                    plan_amount_exclusive: e.price,
                                                                })
                                                            }
                                                        />
                                                        <span>€ {Number(e.price)}</span>
                                                    </div>
                                                );
                                            })}
                                    </div>)}
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {this.props.onResortsPage ? (<button style={{ marginRight: 10 }} onClick={() => {
                                            window.scrollTo(0, window.innerHeight)
                                        }}>Ontdek</button>) : (!this.props.onSpecialProducts) && (<button style={{ marginRight: 10 }} onClick={() => {
                                            this.setState({ eventPopup: true })
                                        }}>Play</button>)}
                                        {this.props.onResortsPage ? (<></>) : this.props.tiktokPage ? (<>
                                            <button style={{ marginRight: 10 }} onClick={() => {
                                                var textField = document.createElement("textarea");
                                                textField.innerText = `${window.location.origin}/special-products`;
                                                document.body.appendChild(textField);
                                                textField.select();
                                                document.execCommand("copy");
                                                textField.remove();
                                                this.setState({ copiedTextDone: true })
                                                setTimeout(() => {
                                                    this.setState({ copiedTextDone: false })
                                                }, 2000);
                                            }}>
                                                Kopieer de link
                                            </button>
                                        </>) : this.props.onSpecialProducts ? (<>
                                            <button style={{ marginRight: 10 }} onClick={() => {
                                                // this.props.moveToCharitySection()
                                                window.scrollTo(0, window.innerHeight)
                                            }}>
                                                Bekijk
                                            </button>
                                            <button onClick={() => {
                                                this.props.moveToAnotherSection()
                                            }}>
                                                Hoe werkt het?
                                            </button>
                                        </>) : this.props.atProductsPage && (this.state.aanmeldenloaderSubscribing ? (
                                            <button>
                                                <div className="lds-ellipsis">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </button>
                                        ) : <button onClick={() => {
                                            if (this.props.atProductsPage) {
                                                this.subscribeForExclusives()
                                            } else {
                                                this.setState({ showFormNewOne: true })
                                            }
                                        }}>{this.state.companyGuestStoryButtonText ? this.state.companyGuestStoryButtonText : "Aanmelden"}</button>)}
                                    </div>
                                </div>)}
                            </div>
                        </div>
                        {/* {this.state.company_on_landing_page && (<NewProductWrapper toShowProductOf={this.state.company_on_landing_page} onGuestUserPage={true} />)} */}
                        {
                            this.state.currentCampaignToShow && this.state.currentCampaignToShow.active && (
                                <div ref={this.sectionRef}>
                                    <div className='top-events-section'>
                                        <p>Event</p>
                                        <p>{this.state.currentCampaignToShow.title}</p>
                                    </div>
                                    <EventsNewSection
                                        campaignToShow={[this.state.currentCampaignToShow]}
                                        onEditOpen={() => {
                                            document.querySelector("html").style.overflowY = "hidden"
                                            this.setState({
                                                openAddPopup: true,
                                                doEditLocalEvent: true,
                                            });
                                        }}
                                        hideBookMarkIcon={true}
                                    />
                                </div>
                            )
                        }
                        {(!this.props.onSpecialProducts && !this.props.onResortsPage) && (<CharityLandingPage dontshowMenu={true} onLandingPage={true} />)}
                        <div className='login-overlay-main lom-new-text' style={{ opacity: this.state.showLoginPanel ? 1 : 0, pointerEvents: this.state.showLoginPanel ? "all" : "none", zIndex: 99999999999 }}>
                            <div className='login-container-lpn'>
                                <MdClose className='icon-person-top-lpn' onClick={() => {
                                    this.setState({ showLoginPanel: false })
                                }} />
                                <h2>LOGIN</h2>
                                {/* {this.state.showLoginPanel && (<form style={{ width: "100%" }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                    <div className={`container-field-cglpn ${this.state.loginEmailPopup ? "containerRedAlerCglpn" : ""}`}>
                                        <input type="email" autoComplete='new-password' placeholder='E-mail' value={this.state.loginEmail} onChange={(e) => {
                                            this.setState({ loginEmail: e.target.value })
                                        }} />
                                    </div>
                                    <div className={`container-field-cglpn ${this.state.loginPasswordPopup ? "containerRedAlerCglpn" : ""}`}>
                                        <input type="password" autoComplete='new-password' placeholder='Wachtwoord' value={this.state.loginPassword} onChange={(e) => {
                                            this.setState({ loginPassword: e.target.value })
                                        }} />
                                    </div>
                                </form>)} */}
                                <p>Beheer eenvoudig je<br />gegevens en je bijdrage.</p>
                                <div className='container-button-cglpn'>
                                    {this.state.aanmeldenloader ? (
                                        <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    ) : (<button onClick={() => this.logIn()} style={{ borderRadius: 6 }}>Klik hier</button>)}
                                    {/* ) : (<button onClick={() => this.logIn()} style={{ borderRadius: 6 }}>Inloggen</button>)} */}
                                </div>
                                {/* <label className='forget-pwd-lpn' onClick={() => {
                                    this.setState({ showLoginPanel: false, showForgetPasswordPanel: true })
                                }}>Wachtwoord vergeten?</label> */}
                            </div>
                        </div>
                        <div className='login-overlay-main' style={{ opacity: this.state.showForgetPasswordPanel ? 1 : 0, pointerEvents: this.state.showForgetPasswordPanel ? "all" : "none", zIndex: 99999999999 }}>
                            <div className='login-container-lpn'>
                                <MdClose className='icon-person-top-lpn' onClick={() => {
                                    this.setState({ showForgetPasswordPanel: false })
                                }} />
                                <h2>FORGET PASSWORD</h2>
                                {this.state.showForgetPasswordPanel && (<form style={{ width: "100%" }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                    <div className={`container-field-cglpn ${this.state.loginEmailPopup ? "containerRedAlerCglpn" : ""}`}>
                                        <input type="email" autoComplete='new-password' placeholder='E-mail' value={this.state.loginEmail} onChange={(e) => {
                                            this.setState({ loginEmail: e.target.value })
                                        }} />
                                        {this.state.emailNotFound && (
                                            <div className="alert-field" style={{ width: "100%" }}>
                                                <span style={{ letterSpacing: 1 }}>
                                                    Email not founded in our records
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </form>)}
                                <div className='container-button-cglpn'>
                                    {this.state.aanmeldenloaderForgetPassword ? (
                                        <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    ) : (<button onClick={() => this.sendResetLink()} style={{ borderRadius: 6 }}>Send</button>)}
                                </div>
                                <label className='forget-pwd-lpn' onClick={() => {
                                    this.setState({ showLoginPanel: true, showForgetPasswordPanel: false })
                                }}>Login?</label>
                            </div>
                        </div>

                        {this.renderAboutPopup()}
                        {this.renderFaqsPopup()}
                        {/* {this.state.storyOpen && (<div className='complete-video-lpn'>
                            <div className='inner-cont-cvlpn' style={{ backgroundColor: "black" }}>
                                <MdClose className='icon-person-top-lpn' style={{ color: "white" }} onClick={() => {
                                    this.setState({ storyOpen: false })
                                }} />
                                <video
                                    src="https://video.bhodi.com/Bhodi.mp4"
                                    autoPlay
                                    loop
                                    playsInline
                                    controls
                                ></video>
                            </div>
                        </div>)} */}
                        {
                            this.state.eventPopup && (<div className='complete-video-lpn'>
                                <div className='inner-cont-cvlpn' style={{ backgroundColor: "black" }}>
                                    <MdClose className='icon-person-top-lpn' style={{ color: "white" }} onClick={() => {
                                        this.setState({ eventPopup: false })
                                    }} />
                                    <video
                                        src="https://video.bhodi.com/Event.mp4"
                                        // src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/Bhodi-video.mp4?alt=media&token=d42c20a1-1944-4b79-b7bb-4ea317a7a69e&_gl=1*13idzsb*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5ODIyOTU1MC40NjYuMS4xNjk4MjMxNDQ1LjU1LjAuMA."
                                        autoPlay
                                        loop
                                        playsInline
                                        controls
                                    ></video>
                                </div>
                            </div>)
                        }
                        {/* {window.innerWidth > 1008 && <Footer notOnMainPage={this.props.atProductsPage ? false : true} />}
                        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={this.props.atProductsPage ? false : true} />} */}
                    </div >
                )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
        global_campaigns: state.campaignsReducer.global_campaigns,
        bhodi_faqs_guests: state.globalReducers.bhodi_faqs_guests,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
        updateBhodiFaqsGuests: (bhodi_faqs_guests) => dispatch(updateBhodiFaqsGuests(bhodi_faqs_guests)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPage);